import { AxiosError } from "axios";
import { ErrorResponse } from "../types";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function getErrorMessage(error: any) {
  return ((error as AxiosError).response?.data as ErrorResponse).message;
}
