import { User, UserRole } from "./../types/index";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const userState = atom<User | null>({
  key: "userState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});
