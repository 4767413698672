import moment from "moment";
import React from "react";
import { HOTELS } from "../constants/hotels";
import { Review } from "../types/review";

type Props = {
  reviews: Review[];
};

const ReviewsReadOnly = ({ reviews }: Props) => {
  return (
    <div className="space-y-2 mt-4">
      {reviews.map((review) => (
        <div
          className="flex items-start py-3 px-4 border-[#eee] rounded-lg font-[16px] space-x-4"
          key={review.id}
        >
          {/* {!review.reviewComment && (
            <div className="w-3 h-3 bg-orange-400 rounded-full mr-4" />
          )} */}
          <div className="flex space-x-2 items-center">
            <img
              src={`/images/${review.id.split("-")[0]}.png`}
              alt=""
              className="w-8 h-8 rounded object-contain"
            />
            <div className="w-[100px]">
              {HOTELS[review.id.split("-")[0]].name}
            </div>
          </div>
          <div className="flex-1">{review.contents}</div>
          <div className="text-xs text-gray-500">
            {moment(review.createdAt).format("YYYY.MM.DD")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewsReadOnly;
