import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import { HashLink } from "react-router-hash-link";
import StarRatings from "react-star-ratings";
import { HOTELS } from "../constants/hotels";
import { Review } from "../types/review";
import NoReview from "./NoReview";

type Props = { reviews: Review[] };

const ReviewsSummary = ({ reviews }: Props) => {
  return (
    <div className="card-container col-span-2 space-y-6">
      <div className="title mb-4">OTA별 리뷰 평점</div>
      <hr />
      {reviews && reviews.length ? (
        <div className="flex space-x-12">
          <div className="flex flex-col items-center justify-center space-y-1">
            <div className="text-lg font-bold">
              {(
                reviews.reduce((sum, review) => sum + review.stars, 0) /
                reviews.length
              ).toFixed(2)}
            </div>
            <div>({reviews.length})</div>
            {reviews && (
              <StarRatings
                starSpacing="0px"
                starDimension="24px"
                rating={
                  !reviews.length
                    ? 5
                    : reviews.reduce((sum, review) => sum + review.stars, 0) /
                      reviews.length
                }
                starRatedColor="orange"
              />
            )}
            <div className="text-orange-400 underline">
              미확인 리뷰{" "}
              {reviews.filter((review) => !review.reviewComment).length}건
            </div>
          </div>
          <div className="flex-1">
            {[5, 4, 3, 2, 1].map((star) => (
              <div className="flex space-x-6 items-center my-1" key={star}>
                <ProgressBar
                  bgColor="#F97316"
                  className="w-full"
                  completed={(
                    (reviews.filter(
                      (review) =>
                        review.stars > star - 1 && review.stars <= star
                    ).length /
                      reviews.length) *
                    100
                  ).toFixed(0)}
                />

                <div className="flex-shrink-0">
                  <StarRatings
                    starSpacing="2px"
                    starDimension="24px"
                    rating={star}
                    starRatedColor="orange"
                  />
                </div>
                <span className="text-gray-500 w-20">
                  {
                    reviews.filter(
                      (review) =>
                        review.stars >= star - 1 && review.stars < star
                    ).length
                  }
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoReview />
      )}
      <hr />
      {/* 호텔별 리뷰 평점 */}
      <div className="flex flex-col">
        {Object.keys(HOTELS).map((key, index) => {
          const hasReviews = reviews.some(
            (review) => review.id.split("-")[0] === key
          );
          if (!hasReviews) return <></>;

          return (
            // TODO: 렌더링이슈로 정확한 위치 이동 안되고 있음.
            <HashLink
              smooth
              to="/review#reviews"
              key={index}
              className={`flex justify-between space-x-4 py-1 hover:bg-zinc-50 cursor-pointer`}
            >
              <div className="flex space-x-2 items-center">
                <img
                  src={`/images/${key}.png`}
                  alt=""
                  className="w-6 h-6 object-contain rounded-full"
                />
                <div className="text-base text-gray-600">
                  {HOTELS[key].name}
                </div>
              </div>
              <div className="text-[#777] text-[16px] space-y-0 flex">
                <div className="flex space-x-4 items-center justify-center">
                  <StarRatings
                    starSpacing="2px"
                    starDimension="24px"
                    rating={
                      reviews
                        .filter((review) => review.id.includes(key))
                        .reduce((sum, review) => sum + review.stars, 0) /
                      reviews.filter((review) => review.id.includes(key)).length
                    }
                    starRatedColor="orange"
                  />
                  <div className="w-24 text-center text-sm">
                    총 리뷰(
                    {reviews.filter((review) => review.id.includes(key)).length}
                    )
                  </div>

                  <div className="w-32 text-center text-sm">
                    미답변리뷰 (
                    {
                      reviews.filter(
                        (review) =>
                          review.id.includes(key) && review.reviewComment
                      ).length
                    }
                    )
                  </div>
                  <div className="text-sm">
                    평균평점 (
                    {(
                      reviews
                        .filter((review) => review.id.includes(key))
                        .reduce((sum, review) => sum + review.stars, 0) /
                      reviews.filter((review) => review.id.includes(key)).length
                    ).toFixed(1)}
                    )
                  </div>
                </div>
              </div>
            </HashLink>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewsSummary;
