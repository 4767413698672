import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Review } from "../types/review";
import { classNames } from "../utils";
import RoundDot from "./RoundDot";

type Props = {
  review: Review;
};

const ReviewAnalysisSummaryContainer = ({ review }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={classNames(
        "px-4 py-3 w-full rounded-lg text-gray-700 text-xs space-y-2 h-fit",
        review.reviewAnalysis.isPositive ? "bg-orange-50" : "bg-red-50"
      )}
    >
      <div className="flex justify-between">
        <div className="text-base font-bold tracking-wide flex items-center space-x-2">
          <RoundDot />
          <span className="font-bold">리뷰 분석 결과</span>
          <div className="">
            {/* <span className="font-bold">긍정 부정 여부: </span> */}
            {review.reviewAnalysis.isPositive ? (
              <span className="text-orange-400 font-bold">긍정 리뷰</span>
            ) : (
              <span className="text-red-400 font-bold">부정 리뷰</span>
            )}
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <ChevronDownIcon className="w-6 h-6 ml-auto text-gray-500" />
          ) : (
            <ChevronUpIcon className="w-6 h-6 ml-auto text-gray-500" />
          )}
        </div>
        {/* <div>
              {review.reviewAnalysis.reviewCategory.map((category) => (
                <span
                  className=""
                  key={category.id}
                >{`${category.large} > ${category.small}`}</span>
              ))}
            </div> */}
      </div>

      {isOpen && review.reviewAnalysis.reviewKeywordCount.length > 0 && (
        <div className="flex flex-col">
          <div className="flex-shrink-0">키워드</div>
          <div className="space-x-1 font-bold flex flex-wrap">
            {review.reviewAnalysis.reviewKeywordCount
              // .slice(0, 500)
              .map((keyword) => (
                <span key={keyword.id} className="text-light">
                  #{keyword.keyword}
                </span>
              ))}
          </div>
        </div>
      )}
      {/* {review.reviewAnalysis.reviewKeywordCount.length > 5 ? (
        <Collapse>
          <Collapse.Panel
            header="키워드"
            // className="font-bold flex-shrink-0"
            key={1}
          >
            {review.reviewAnalysis.reviewKeywordCount.map(
              (keywordCount) => (
                <span
                  color=""
                  key={keywordCount.id}
                  className="border rounded-full border-primary text-primary mx-0.5 my-0.5 px-1 py-0.5 whitespace-nowrap hover:bg-primary hover:text-white cursor-pointer transition-colors"
                >
                  #{keywordCount.keyword}
                </span>
              )
            )}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <>
          {review.reviewAnalysis.reviewKeywordCount.map(
            (keywordCount) => (
              <span
                color=""
                key={keywordCount.id}
                className="border rounded-full border-primary text-primary mx-0.5 my-0.5 px-1 py-0.5 whitespace-nowrap hover:bg-primary hover:text-white cursor-pointer transition-colors"
              >
                #{keywordCount.keyword}
              </span>
            )
          )}
        </>
      )} */}

      {isOpen && review.reviewAnalysis.reviewPositiveWord.length > 0 && (
        <div className="">
          <div className="">긍정 키워드</div>
          <div className="space-x-1 font-bold">
            {review.reviewAnalysis.reviewPositiveWord.map((keyword) => (
              <span key={keyword.id} className="text-light">
                #{keyword.word}
              </span>
            ))}
          </div>
        </div>
      )}

      {isOpen && review.reviewAnalysis.reviewNegativeWord.length > 0 && (
        <div className="">
          <div className="">부정 키워드</div>
          <div className="space-x-1">
            {review.reviewAnalysis.reviewNegativeWord.map((keyword) => (
              <span key={keyword.id} className="text-light font-bold">
                #{keyword.word}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewAnalysisSummaryContainer;
