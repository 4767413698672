import axios from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";

export const authAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 30000,
  withCredentials: true
});

authAxios.interceptors.request.use(
  function (config: any) {
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      ACCESS_TOKEN
    )}`;
    return config;
  },
  function (error) {
    console.log("authaxios request error", error);
    return Promise.reject(error);
  }
);

let isTokenRefreshing = false;
let refreshSubscribers: any = [];

const onTokenRefreshed = (accessToken: any) => {
  refreshSubscribers.map((callback: any) => callback(accessToken));
};

const addRefreshSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

authAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    if (!isTokenRefreshing) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/refresh`,
          { refreshToken: refreshToken }
        );
        console.log("[TOKEN REFRESHED]");
        const newAccessToken = data.accessToken;
        const newRefreshToken = data.refreshToken;
        localStorage.setItem(ACCESS_TOKEN, newAccessToken);
        localStorage.setItem(REFRESH_TOKEN, newRefreshToken);
        isTokenRefreshing = false;
        axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
        onTokenRefreshed(newAccessToken);
      } catch (e) {
        console.log(e);
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
      }
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addRefreshSubscriber((accessToken: string) => {
        originalRequest.headers.Authorization = "Bearer " + accessToken;
        resolve(axios(originalRequest));
      });
    });
    return retryOriginalRequest;
  }
);

export const defaultAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true
});
