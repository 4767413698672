import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import MainLayout from "../layouts/MainLayout";
import { Notice } from "../types/notice";
import { fetcher } from "../utils/swr";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../atoms/user.atom";
import { authAxios } from "../utils/axios";
import { toast } from "react-toastify";
type Props = {};

const NoticePage = (props: Props) => {
  const [page, setPage] = useState<number>(1);
  const [notices, setNotices] = useState<Notice[]>([]);
  const [count, setCount] = useState<number>(0);
  const [take, setTake] = useState<number>(10);
  const [user, setUser] = useRecoilState(userState);
  const [columns, setColumns] = useState<ColumnsType<Notice>>();

  const { data: response, error } = useSWR(
    `/notice?page=${page}&take=${take}`,
    fetcher
  );

  useEffect(() => {
    if (response?.data) {
      setNotices(response.data.notices);
      setCount(response.data.count);
    }
  }, [response]);

  useEffect(() => {
    const defaultColumns: ColumnsType<Notice> = [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: "70px",
        align: "center",
        render: (id) => <span>{id}</span>
      },
      {
        title: "제목",
        dataIndex: "title",
        key: "title",
        render: (text, notice) => (
          <Link to={`/notice/${notice.id}`}>
            <span className="text-gray-800 hover:text-orange-400">{text}</span>
          </Link>
        )
      },
      {
        title: "작성일",
        dataIndex: "createdAt",
        key: "createdAt",
        width: "200px",
        align: "center",
        render: (createdAt) => (
          <span>{moment(createdAt).format("YYYY.MM.DD HH:mm:ss")}</span>
        )
      }
    ];
    if (user && user.role === "ADMIN") {
      defaultColumns.push({
        title: "관리자 기능",
        key: "action",
        width: "150px",
        align: "center",
        render: (_, notice) => (
          <div className="space-x-4 flex items-center justify-center">
            <Link
              to={`/notice/create-edit/${notice.id}`}
              className="text-black cursor-pointer hover:text-primary"
              onClick={() => {}}
            >
              수정
            </Link>
            <div
              className="cursor-pointer hover:text-primary"
              onClick={() => onDelete(notice.id)}
            >
              삭제
            </div>
          </div>
        )
      });
    }
    setColumns(defaultColumns);
  }, [columns, user]);

  const onDelete = async (id: number) => {
    if (window.confirm(`정말로 공지를 삭제하시겠습니까?`)) {
      try {
        await authAxios.delete(`/notice/${id}`);
        toast.success("공지가 삭제되었습니다.");
        setNotices(notices.filter((notice) => notice.id !== id));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <MainLayout>
      {/* <h3 className="title">공지사항</h3> */}
      <div className="px-4 sm:px-6 lg:px-2">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto text-center">
            <div className="title">공지사항</div>
            <div className="font-light mt-4">
              잠자리의 새로운 소식을 확인하세요
            </div>
          </div>
        </div>
        <Table
          dataSource={notices}
          columns={columns}
          bordered
          className="mt-10"
          rowKey={(notice) => notice.id}
        />

        <div className="flex justify-end mt-4">
          <Link to={"/notice/create-edit"} type="button" className="button">
            추가하기
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default NoticePage;
