import React from "react";

type Props = {};

const NoReview = (props: Props) => {
  return (
    <div>
      <div> 등록된 리뷰가 없습니다. 호텔을 등록해주세요. 😭</div>
    </div>
  );
};

export default NoReview;
