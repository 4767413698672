import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import ContactForm from "../components/ContactForm";
import MainLayout from "../layouts/MainLayout";
import { classNames } from "../utils";

const faqs = [
  {
    question: "질문1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum repudiandae veniam voluptas et velit, delectus magni facere in odio, exercitationem quisquam! Et suscipit corrupti vel, obcaecati quia temporibus necessitatibus veritatis."
  },
  {
    question: "질문2",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quaerat, sed assumenda dolore repudiandae accusantium soluta excepturi odit sunt quisquam inventore natus expedita laborum maiores ut ducimus vitae ratione? Distinctio?"
  },
  {
    question: "질문3",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam incidunt esse similique. Qui cum doloribus error, alias voluptas quas quam doloremque. Asperiores nam quos aliquam eligendi nesciunt commodi voluptatibus nulla."
  },
  {
    question: "질문4",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima libero molestias impedit ducimus officiis nobis, tempora neque quasi rerum sunt sed eius laborum ex nam, unde, exercitationem molestiae. Sed, dolores?"
  },
  {
    question: "질문5",
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur, corrupti fugit atque aliquid laudantium dolor expedita possimus maxime accusamus officiis eaque porro, exercitationem quos! Neque pariatur nam dignissimos iure placeat!"
  }
];

const CustomerPage = () => {
  return (
    <MainLayout>
      <div className="space-y-24">
        {false && (
          <div className="overflow-hidden bg-white  px-4 sm:px-6 lg:px-8 ">
            <div className="mx-auto max-w-7xl px-4  sm:px-6 lg:px-8">
              <div className="mx-auto">
                <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  자주 묻는 질문
                </h2>
                <dl className="mt-6 space-y-1">
                  {faqs.map((faq, index) => (
                    <Disclosure as="div" key={index} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt className="text-lg">
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400 bg-gray-50 rounded-lg px-8 py-4">
                              <div className="space-x-4">
                                <span className="text-primary font-medium">
                                  Q{index + 1}
                                </span>
                                <span className="font-medium text-gray-900">
                                  {faq.question}
                                </span>
                              </div>
                              <span className="ml-6 flex h-7 items-center">
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "-rotate-180" : "rotate-0",
                                    "h-6 w-6 transform"
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel
                            as="dd"
                            className="mt-2 pr-12  px-8 py-4"
                          >
                            <p className="text-base text-gray-500 space-x-4">
                              <span className="text-primary font-medium">
                                A{index + 1}
                              </span>
                              <span>{faq.answer}</span>
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        )}
        {/* contact */}
        <div className="mx-auto w px-40 bg-orange-50 py-24">
          <div className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">
            고객센터 문의하기
          </div>
          <div className="text-center text-light"></div>
          <div className="mt-14">
            <ContactForm />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CustomerPage;
