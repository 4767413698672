import { Link } from "react-router-dom";

type Props = {};

const Banner = (props: Props) => {
  return (
    <div className="flex items-center  justify-center bg-primary py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
      <Link to={"/payment"} className="!text-white !hover:text-gray-500">
        <span className="text-white">
          유료 회원이 되어서 더 많은 기능을 사용해보세요!
        </span>
        <span aria-hidden="true">&rarr;</span>
      </Link>
    </div>
  );
};

export default Banner;
