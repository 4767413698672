import React, { ReactElement, useEffect } from "react";

type Props = {
  title?: string | undefined;
  children: string | undefined | ReactElement | ReactElement[];
};

const Page = ({ title, children }: Props) => {
  useEffect(() => {
    document.title = title ? `${title} | 잠자리` : "잠자리";
    window.scrollTo(0, 0);
  }, [title]);

  return <div>{children}</div>;
};

export default Page;
