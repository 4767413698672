import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import useSWR from "swr";
import { userState } from "../atoms/user.atom";
import MainLayout from "../layouts/MainLayout";
import { Notice } from "../types/notice";
import { fetcher } from "../utils/swr";

type Props = {};

export const NoticeDetailPage = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notice, setNotice] = useState<Notice>();

  const { data, error } = useSWR(`/notice/${id}`, fetcher);

  useEffect(() => {
    if (data?.data.data) {
      setNotice(data.data.data);
    }
  }, [data]);

  return (
    <MainLayout>
      <div className="relative overflow-hidden bg-white">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]"></div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <span className="block text-center text-lg font-semibold text-orange-400">
              공지사항
            </span>
            <span className="mt-4 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              {notice?.title}
            </span>
            <div className="text-center text-light font-sm mt-4">
              {moment(notice?.createdAt).format("YYYY.MM.DD HH:mm:ss")}
            </div>
          </div>
          <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500 border px-12 py-8 rounded">
            <p dangerouslySetInnerHTML={{ __html: notice?.contents ?? "" }}></p>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end space-x-2">
        <button className="button" onClick={() => navigate(-1)}>
          <div>뒤로가기</div>
        </button>
        {/* <button className="button" onClick={() => navigate(-1)}>
          <div>수정</div>
        </button>

        <button className="button" onClick={() => navigate(-1)}>
          <div>삭제</div>
        </button> */}
      </div>
    </MainLayout>
  );
};
