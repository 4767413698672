import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useSWR, { useSWRConfig } from "swr";
import HotelIcon from "../components/HotelIcon";
import ModalBase from "../components/ModalBase";
import { HOTELS } from "../constants/hotels";
import MainLayout from "../layouts/MainLayout";
import { User } from "../types";
import { Account } from "../types/account";
import { authAxios } from "../utils/axios";
import { fetcher } from "../utils/swr";

const AdminPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { data, error } = useSWR("/users", fetcher);

  const { mutate } = useSWRConfig();

  const [openEditUserModal, setOpenEditUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const { register, handleSubmit, reset } = useForm();

  const onEditUserOpen = useCallback((user: User) => {
    setSelectedUser(user);
    setOpenEditUserModal(true);
  }, []);

  const onEditUserAccount = useCallback(
    async (value: any) => {
      await authAxios.patch(`/users/accounts/${selectedUser?.id}`, value);
      // reset();
      setOpenEditUserModal(false);
      // setSelectedUser(undefined);
      mutate("/users");
    },
    [mutate, selectedUser?.id]
  );

  const onCancelEditAccount = useCallback(() => {
    reset();
    setOpenEditUserModal(false);
    // setSelectedUser(undefined);
  }, [reset]);

  const onUserStatusChange = useCallback(
    async (userId: number, status: boolean) => {
      await authAxios.post("/users/status", {
        userId,
        status
      });
      await mutate("/users");

      if (status) {
        toast.success(`유저를 활성 상태로 변경 했습니다.`);
      } else {
        toast.warn("유저를 비활성 상태로 변경 하였습니다.");
      }
    },
    [mutate]
  );

  const onUserDelete = useCallback(
    async (userId: number) => {
      if (
        window.confirm(
          "정말로 유저를 삭제하시겠습니까? 유저와 관련된 모든 데이터가 삭제됩니다."
        )
      ) {
        await authAxios.delete(`/users/${userId}`);
        await mutate("/users");
      }
    },
    [mutate]
  );

  const columns: ColumnsType<User> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (id) => <span className="">{id}</span>
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
      render: (email) => <div>{email}</div>
    },

    {
      title: "등록 호텔",
      dataIndex: "accounts",
      key: "accounts",
      filters: Object.keys(HOTELS)
        .map((key) => HOTELS[key])
        .map((hotel) => {
          return { value: hotel.id, text: hotel.name };
        }),
      onFilter: (value: any, user: User) =>
        user.accounts.some((account) => account.provider === value),
      render: (accounts) =>
        accounts && (
          <div className="flex space-x-2">
            {accounts.map((account: Account) => (
              <HotelIcon
                key={account.id}
                size={6}
                siteName={account.provider}
              />
            ))}
          </div>
        )
    },
    {
      title: "활성 사용자",
      dataIndex: "isActive",
      key: "isActive",
      filters: [
        {
          text: "활성",
          value: true
        },
        {
          text: "비활성",
          value: false
        }
      ],
      onFilter: (value: any, user) => user.isActive === value,
      render: (isActive) => <div>{isActive ? "활성" : "비활성"}</div>
    },
    {
      title: "유료 사용자",
      dataIndex: "isPaid",
      key: "isPaid",
      filters: [
        {
          text: "유료",
          value: true
        },
        {
          text: "무료",
          value: false
        }
      ],
      onFilter: (value: any, user) => user.isPaid === value,
      render: (isPaid) => <div>{isPaid ? "유료" : "무료"}</div>
    },
    {
      title: "가입일",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: User, b: User) =>
        moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1,
      render: (createdAt) => (
        <div className="text-xs text-gray-500">
          {moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      )
    },
    {
      title: "최근 로그인",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: User, b: User) =>
        moment(a.updatedAt).isBefore(b.updatedAt) ? -1 : 1,
      render: (updatedAt) => (
        <div className="text-xs text-gray-500">
          {moment(updatedAt).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      )
    },
    {
      title: "상태 변경",
      dataIndex: "",
      key: "",

      render: (_, user: User) => (
        <div className="space-x-2">
          <button
            className="button-white !px-2 !py-1"
            onClick={() => onEditUserOpen(user)}
          >
            수정
          </button>
          {!user.isActive ? (
            <button
              className="button-green !px-2 !py-1"
              onClick={() => onUserStatusChange(user.id, true)}
            >
              정지 해제
            </button>
          ) : (
            <button
              className="button-red !px-2 !py-1"
              onClick={() => onUserStatusChange(user.id, false)}
            >
              정지
            </button>
          )}
          <button
            className="button-red !px-2 !py-1"
            onClick={() => onUserDelete(user.id)}
          >
            탈퇴
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    if (data?.data.users) {
      setUsers(data.data.users);
    }
  }, [data, users]);

  return (
    <MainLayout>
      <ModalBase
        open={openEditUserModal}
        setOpen={setOpenEditUserModal}
        width="sm:max-w-4xl"
      >
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={handleSubmit(onEditUserAccount)}
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                유저 정보 수정
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {selectedUser?.email}
              </p> */}
              <p className="mt-1 max-w-2xl text-sm font-bold text-red-500">
                * [주의] 함부로 수정하시면 안됩니다. 반드시 변경할 유저 정보만
                수정하세요.
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  이메일
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    disabled
                    value={selectedUser?.email}
                    type="text"
                    name="email"
                    id="email"
                    className="disabled:bg-gray-100 disabled:text-gray-500 input"
                  />
                </div>
              </div>

              {selectedUser?.accounts.map((account) => (
                <div
                  key={account.id}
                  className=" sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                >
                  {/* <label
                    htmlFor={`${account.provider}-username`}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    <div className="flex space-x-2 items-center">
                      <span>{HOTELS[account.provider]?.name} 아이디</span>
                    </div>
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      {...register(`${account.provider}-username`)}
                      defaultValue={account.username ?? ""}
                      type="text"
                      name={`${account.provider}-username`}
                      id={`${account.provider}-username`}
                      className="input"
                    />
                  </div> */}

                  {/* <label
                    htmlFor={`${account.provider}-password`}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    <div className="flex space-x-2 items-center">
                      <span>{HOTELS[account.provider]?.name} 비밀번호</span>
                    </div>
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      {...register(`${account.provider}-password`)}
                      defaultValue={account.password ?? ""}
                      type="text"
                      name={`${account.provider}-password`}
                      id={`${account.provider}-password`}
                      className="disabled:bg-gray-100 disabled:text-gray-500 input"
                    />
                  </div> */}

                  <label
                    htmlFor={`${account.provider}-hotelName`}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    <div className="flex space-x-2 items-center">
                      <span>{HOTELS[account.provider]?.name} 호텔 이름</span>
                    </div>
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="호텔 이름을 입력해주세요"
                      {...register(`${account.provider}-hotelName`)}
                      defaultValue={account.hotelName ?? ""}
                      type="text"
                      name={`${account.provider}-hotelName`}
                      id={`${account.provider}-hotelName`}
                      className="input"
                    />
                  </div>

                  <label
                    htmlFor={`${account.provider}-hotelUrl`}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    <div className="flex space-x-2 items-center">
                      <span>{HOTELS[account.provider]?.name} 호텔 링크</span>
                    </div>
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="호텔 주소를 입력해주세요"
                      {...register(`${account.provider}-hotelUrl`)}
                      defaultValue={account.hotelUrl ?? ""}
                      type="text"
                      name={`${account.provider}-hotelUrl`}
                      id={`${account.provider}-hotelUrl`}
                      className="input"
                    />
                  </div>

                  <label
                    htmlFor={`${account.provider}-placeId`}
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    <div className="flex space-x-2 items-center">
                      <span>
                        {HOTELS[account.provider]?.name} 호텔 고유번호
                      </span>
                    </div>
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="호텔 고유번호를 입력해주세요."
                      {...register(`${account.provider}-placeId`)}
                      defaultValue={account.placeId ?? ""}
                      type="text"
                      name={`${account.provider}-placeId`}
                      id={`${account.provider}-placeId`}
                      className="input"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                className="button-white"
                onClick={onCancelEditAccount}
              >
                취소
              </button>
              <button type="submit" className="button ml-2">
                저장
              </button>
            </div>
          </div>
        </form>
      </ModalBase>
      <h3 className="title">사용자 가입 정보</h3>
      <div className="">
        <Table
          className=""
          locale={{ emptyText: "유저가 없습니다." }}
          dataSource={users}
          columns={columns}
          rowKey={(row) => row.id}
          bordered
        />
      </div>
    </MainLayout>
  );
};

export default AdminPage;
