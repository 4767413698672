import React from "react";
import { PRIVACY_TEXT } from "../constants";
import LandingLayout from "../layouts/LandingLayout";

type Props = {};

const PrivacyPage = (props: Props) => {
  return (
    <LandingLayout>
      <div className="whitespace-pre-wrap max-width py-20">{PRIVACY_TEXT}</div>
    </LandingLayout>
  );
};

export default PrivacyPage;
