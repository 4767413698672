import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Page title="404">
      <div className="h-screen flex flex-col items-center justify-center space-y-0">
        <img src="/images/logo.png" alt="" className="w-12 h-12" />
        <div className="flex items-center justify-center py-10 font-base text-xl">
          페이지를 찾을 수 없습니다.
        </div>
        <button className="button" onClick={() => navigate("/home")}>
          뒤로가기
        </button>
      </div>
    </Page>
  );
};

export default NotFoundPage;
