import { useCallback, useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import { Review } from "../types/review";
import { fetcher } from "../utils/swr";
import MainLayout from "../layouts/MainLayout";
import { classNames, getErrorMessage } from "../utils";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { Collapse, Image, Table, Tag } from "antd";
import StarRatings from "react-star-ratings";
import ReviewCommentTextArea from "../components/ReviewCommentTextArea";
import { HOTELS } from "../constants/hotels";
import { authAxios } from "../utils/axios";
import { ReviewUserComment, User } from "../types";
import { toast } from "react-toastify";
import { WordCloud } from "../types/wordCloud";
import { motion } from "framer-motion";
import RoundDot from "../components/RoundDot";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms/user.atom";
import ReviewsSummary from "../components/ReviewsSummary";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import ReviewAnalysisSummaryContainer from "../components/ReviewAnalysisSummaryContainer";

const Reviews = () => {
  const [reviews, setReviews] = useState<Review[]>([]);

  const [reviewUserComments, setReviewUserComments] = useState<
    ReviewUserComment[]
  >([]);

  const { mutate } = useSWRConfig();

  const user = useRecoilValue(userState);

  const onDeleteComment = useCallback(
    async (review: Review) => {
      if (review.reviewComment?.id.includes("temp")) {
        alert(
          "잠자리에서 등록한 답글은 최대 1시간 이후 부터 삭제가 가능합니다."
        );
        return;
      }

      if (!window.confirm("정말로 답글을 삭제하시겠습니까?")) return;

      toast.loading("댓글 삭제 중...");

      try {
        await authAxios.delete(
          `/reviews/comment?reviewId=${review.id.split("-")[1]}&commentId=${
            review.reviewComment?.id.split("-")[1]
          }&provider=${review.id.split("-")[0]}`,
          {}
        );
      } catch (e) {
        console.error(e);
        alert(getErrorMessage(e));
      } finally {
        toast.dismiss();
      }

      await mutate("/reviews");
    },
    [mutate]
  );

  // swr get reviews
  const { data, error } = useSWR("/reviews", fetcher);
  useEffect(() => {
    if (data && data.data && !error) {
      setReviews(data.data.reviews);

      // console.log(data.data.reviews);

      let tempWordCloudTotal: { [id: string]: number } = {};
      let tempWordCloudPositive: { [id: string]: number } = {};
      let tempWordCloudNegative: { [id: string]: number } = {};

      data.data.reviews
        .filter((review: Review) => review.reviewAnalysis)
        .forEach((review: Review) => {
          review.reviewAnalysis.reviewKeywordCount.forEach(
            (keyword: WordCloud) => {
              tempWordCloudTotal[keyword.keyword] =
                (tempWordCloudTotal[keyword.keyword] ?? 0) + keyword.count;
            }
          );

          review.reviewAnalysis.reviewPositiveWord.forEach((keyword: any) => {
            tempWordCloudPositive[keyword.word] =
              (tempWordCloudPositive[keyword.word] ?? 0) + 1;
          });

          review.reviewAnalysis.reviewNegativeWord.forEach((keyword: any) => {
            tempWordCloudNegative[keyword.word] =
              (tempWordCloudNegative[keyword.word] ?? 0) + 1;
          });
        });
    }
  }, [data, error]);

  // useEffect(() => {
  //   setStats({
  //     today: reviews.filter((review) => {
  //       const reviewDate = review.createdAt;
  //       const isSameDate =
  //         moment(new Date()).year() === moment(reviewDate).year() &&
  //         moment(new Date()).month() === moment(reviewDate).month() &&
  //         moment(new Date()).day() === moment(reviewDate).day();
  //       return isSameDate;
  //     }).length,
  //     complete: reviews.filter((review) => review.reviewComment).length,
  //     average:
  //       reviews.reduce((sum, review) => sum + review.stars, 0) / reviews.length
  //   });
  // }, [reviews]);

  // swr get review user comments
  const { data: userCommentData, error: userCommentError } = useSWR(
    "/users/comment",
    fetcher
  );

  useEffect(() => {
    if (userCommentData && userCommentData.data && !userCommentError) {
      setReviewUserComments(userCommentData.data.comments);
    }
  }, [userCommentData, userCommentError]);

  const reviewColumns: ColumnsType<Review> = [
    {
      title: "호텔",
      dataIndex: "id",
      key: "id",
      width: "150px",
      filters: Object.keys(HOTELS)
        .map((key) => HOTELS[key])
        .map((hotel) => {
          return {
            value: hotel.id,
            text: hotel.name,
          };
        }),
      onFilter: (value: any, record: Review) =>
        record.id.split("-")[0] === value.split("-")[0],
      render: (id, review) => (
        <div className="flex flex-col items-center space-y-2">
          <img
            alt={review.id.split("-")[0]}
            src={`/images/${review.id.split("-")[0]}.png`}
            className="mr-2 h-6 w-6 object-contain"
          ></img>
          <div className="text-gray-400 text-xs">
            {moment(review.createdAt).format("YYYY.MM.DD")}
          </div>
          <div className="pb-1">
            <StarRatings
              rating={review.stars}
              starRatedColor="orange"
              starDimension="15px"
              starSpacing="0px"
              numberOfStars={5}
              name="rating"
            />
          </div>
        </div>
      ),
    },
    {
      title: "리뷰",
      key: "contents",
      filters: [
        {
          text: "답글 미등록",
          value: false,
        },
        {
          text: "답글 등록",
          value: true,
        },
      ],
      onFilter: (value: any, review: Review) =>
        Boolean(review.reviewComment) === value,
      render: (contents, review) => (
        <div className="flex flex-col">
          <div className="block md:hidden">
            <img
              alt={review.id.split("-")[0]}
              src={`/images/${review.id.split("-")[0]}.png`}
              className="mr-2 h-10  w-10"
            ></img>
          </div>
          <div className="block lg:hidden">
            <StarRatings
              key={review.id}
              rating={review.stars}
              starRatedColor="orange"
              starDimension="15px"
              starSpacing="0px"
              numberOfStars={5}
              name="rating"
            />
          </div>

          {/* 리뷰 */}
          <div className=" text-gray-800 hover:text-gray-900">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <div>
                  {/* 리뷰 이미지 */}
                  <div className="grid grid-cols-3 gap-4">
                    {review.photos &&
                      review.photos.split(",").map((image) => (
                        <div className="my-2" key={image}>
                          {/* <a key={image} href={image} target="_blank" rel="noreferrer"> */}
                          <Image
                            width={200}
                            height={200}
                            src={image}
                            alt={image}
                            className="h-40 w-40 object-cover"
                          />
                          {/* </a> */}
                        </div>
                      ))}
                  </div>
                  <div>
                    <span>{review.contents} </span>
                    <span className="text-xs text-gray-600">
                      ({review.writer})
                    </span>
                  </div>
                </div>
              </div>
              {/* ai 분석 결과 */}
              {review.reviewAnalysis && (
                <ReviewAnalysisSummaryContainer
                  review={review}
                ></ReviewAnalysisSummaryContainer>
              )}
            </div>
          </div>

          {/* 등록일 */}
          <div>
            <div className="my-1 flex justify-end space-x-2 text-xs text-gray-400 md:hidden">
              <span>{moment(review.createdAt).format("YYYY.MM.DD")}</span>
            </div>
          </div>
          {/* <ReviewCommentInput review={review} summary={summary} /> */}

          {review.reviewComment ? (
            review.reviewComment.contents && (
              <div
                className={classNames(
                  "mt-2 flex flex-col rounded-lg  px-4 py-4",
                  review.reviewComment.isFromZzamzari
                    ? "bg-orange-100 border border-orange-200"
                    : "bg-gray-100"
                )}
              >
                <span>{review.reviewComment.contents}</span>

                <span className="text-[5px] text-gray-400">
                  {moment(review.reviewComment.createdAt).format("YYYY.MM.DD")}
                </span>
              </div>
            )
          ) : (
            <ReviewCommentTextArea
              review={review}
              setReviews={setReviews}
              reviewUserComments={reviewUserComments}
            />
            // <div
            //   className={classNames(
            //     "mt-2 flex flex-col rounded-lg  px-4 py-4 bg-gray-100"
            //   )}
            // >
            //   {/* <span>{review.reviewComment.contents}</span> */}

            //   <span className="text-[5px] text-gray-400">
            //     아직 답글이 등록되지 않았습니다.
            //     {/* {moment(review.reviewComment.createdAt).format("YYYY.MM.DD")} */}
            //   </span>
            // </div>
            // <div className="ml-auto mt-4">
            //   <a
            //     className="button"
            //     href={HOTELS[review.id.split("-")[0]].adminPage}
            //     target="_blank"
            //     rel="noreferrer"
            //   >
            //     답글 작성 하러가기
            //   </a>
            // </div>
          )}
        </div>
      ),
    },
    {
      title: "등록일",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "80px",
      sorter: (a: Review, b: Review) =>
        moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1,
      render: (createdAt) => (
        <div className="text-gray-400 text-xs">
          {moment(createdAt).format("YYYY.MM.DD")}
        </div>
      ),
    },
  ];

  return (
    <MainLayout title="리뷰">
      <div className="space-y-24">
        <ReviewsSummary reviews={reviews} />
        {/* reviews */}
        <div id="reviews" className="card-container">
          <h3 className="title mt-6 mb-2">등록된 리뷰</h3>
          <Table
            className=""
            bordered
            dataSource={reviews}
            columns={reviewColumns}
            rowKey={(row) => row.id}
            pagination={{ pageSize: 20 }}
          />
          {/* <ReviewTable reviews={reviews} /> */}
        </div>
      </div>
    </MainLayout>
  );
};

export default Reviews;
