import React from "react";

type Props = {
  siteName: string;
  size?: number;
};

const HotelIcon = ({ siteName, size = 10 }: Props) => {
  return (
    <img
      alt={siteName}
      src={`/images/${siteName}.png`}
      className={`h-${size} w-${size} rounded object-contain`}
    ></img>
  );
};

export default HotelIcon;
