import { PhoneIcon } from "@heroicons/react/24/solid";
import AnchorLink from "react-anchor-link-smooth-scroll";
import React, { ReactElement } from "react";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import Footer from "../components/Footer";

type Props = {
  children: string | undefined | ReactElement | ReactElement[];
};

const LandingLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 scoll font-nanum">
      <div className="bg-dark w-full h-[70px] fixed top-0 left-0 right-0 border-b border-gray-800 z-10">
        dd
      </div>
      <header className="mx-auto max-w-7xl px-8 bg-dark h-[70px] flex items-center justify-between border-b border-gray-800 fixed left-0 right-0 z-10">
        <Link
          to="/"
          className="text-2xl font-light text-orange-400 hover:text-orange-400 font-abel"
        >
          Zzamzari
        </Link>
        {pathname !== "/login" && (
          <div className="hidden md:flex justify-center items-center space-x-6 transition-colors">
            <AnchorLink offset={100} href="#home" className="menu-text-link">
              Home
            </AnchorLink>
            <AnchorLink offset={100} href="#review" className="menu-text-link">
              Review
            </AnchorLink>
            <AnchorLink offset={70} href="#function" className="menu-text-link">
              Function
            </AnchorLink>
            {/* <AnchorLink offset={70} href="#price" className="menu-text-link">
              Price
            </AnchorLink> */}
            <AnchorLink
              offset={70}
              href="#application"
              className="menu-text-link"
            >
              Application
            </AnchorLink>

            <AnchorLink offset={70} href="#contact" className="menu-text-link">
              Contact
            </AnchorLink>
          </div>
        )}
        <div className="flex space-x-2">
          <div className="hidden md:flex">
            <div
              className="button-white !px-4 !py-1"
              onClick={() => navigate("/login")}
            >
              로그인
            </div>
          </div>
          <div className="hidden md:flex">
            <div
              className="button !px-4 !py-1"
              onClick={() => navigate("/register")}
            >
              무료로 시작하기
            </div>
          </div>
        </div>

        <div className="block md:hidden">
          <svg
            className="w-6 h-6 text-white cursor-pointer hover:text-orange-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </header>
      <main className="mt-[70px]">{children}</main>
      <Footer />
    </div>
  );
};

export default LandingLayout;
