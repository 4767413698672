import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Page from "../components/Page";
import LandingLayout from "../layouts/LandingLayout";
import { classNames } from "../utils";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ContactForm from "../components/ContactForm";
import { HOTELS } from "../constants/hotels";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms/user.atom";
SwiperCore.use([Navigation, Pagination]);

const AppUserReviews = [
  {
    username: "홍길동",
    hotelName: "인천 을왕 씨사이트 호텔",
    review:
      "워낙 많은 예약 채널을 사용하는 탓에 리뷰 관리가 번거로웠는데 이제 리뷰를 한 곳에서 모아보니 훨씬 편해졌어요",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/33/fd/0a/incheon.jpg?w=1400&h=-1&s=1",
  },
  {
    username: "홍길동",
    hotelName: "비토 더 뷰 글램핑",
    review:
      "다른 업무에 밀려 리뷰 관리에 반쯤 손을 놓았었는데 적은 비용으로 리뷰 관리가 가능해져 든든해요 ",
    image:
      "https://yaimg.yanolja.com/v5/2022/12/01/14/640/6388b8d4462b62.27648499.jpg",
  },
  {
    username: "홍길동",
    hotelName: "인천 프린스 호텔",
    review:
      "한 페이지에서 모든 리뷰를 관리하고 분석까지 할 수 있어 더욱 효율적인 업무가 가능해졌습니다",
    image:
      "https://images.trvl-media.com/hotels/67000000/66800000/66796800/66796774/502ceb43_z.jpg",
  },
  {
    username: "홍길동",
    hotelName: "인천 무의도 씨사이드 호텔",
    review:
      "답글 복붙은 이제 그만! 리뷰 내용에 따라 새로운 내용으로 답글을 알아서 제공해주니 스트레스도 함께 줄었어요",
    image:
      "https://ak-d.tripcdn.com/images/220g1900000167exc3ABE_R_200_133_R5_D.jpg",
  },
  {
    username: "홍길동",
    hotelName: "사천 솔섬 오토캠핑장",
    review:
      "잠자리 서비스가 저 대신 악성 리뷰에도 친절하게 답해주는 것이 큰 장점 중 하나인 것 같습니다",
    image:
      "https://www.gocamping.or.kr/upload/camp/1776/thumb/thumb_720_7922zTbLn3nQp8qLRrnUilVU.jpg",
  },
];

type Props = {};

const LandingPage = (props: Props) => {
  const navigate = useNavigate();
  const [functionIndex, setFunctionIndex] = useState(0);

  const user = useRecoilValue(userState);

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [navigate, user]);

  return (
    <Page>
      <LandingLayout>
        <div className="bg-orange-50">
          <div className="pt-4 md:pt-20 pb-40 bg-orange-50 grid grid-cols-1 md:grid-cols-2 max-width items-center">
            <div className="flex flex-col items-start" id="home">
              <div className="text-5xl font-bold font-abel">Zzamzari</div>
              <div className="text-2xl text-dark mb-10 leading-snug mt-10">
                <div>
                  오직 숙소 사장님을 위한, <br />단 하나의 AI리뷰 관리 비서,{" "}
                  <span className="text-primary">잠자리</span> 입니다.
                </div>
              </div>
              <div className="text-dark text-xl">
                <span className="leading-normal">
                  여러 사이트의 이용후기 관리를 한 곳에서! <br />
                  쉽고 편리한 리뷰관리 서비스 지금 시작해보세요
                </span>
              </div>
              <div className="my-16 flex space-x-2">
                {Object.keys(HOTELS).map((hotel, index) => (
                  <img
                    src={`/images/${hotel}.png`}
                    alt=""
                    className="w-10 h-10 object-contain rounded"
                  />
                ))}
              </div>
              <div className="flex max-w-lg space-x-4">
                <Link
                  to={"/register"}
                  className="button !px-10 !py-4 !text-2xl"
                >
                  무료로 시작하기
                </Link>
              </div>
            </div>
            <div className="">
              <img src={"/images/bg-landing-1.png"} alt="" className="" />
            </div>
          </div>
        </div>
        <div className="-mt-96 flex md:hidden items-center justify-center pb-20">
          <div className="swiper-button-prev-unique">
            <ChevronLeftIcon className="w-8 h-8 text-gray-400" />
          </div>
          <Swiper
            spaceBetween={50}
            slidesPerView={3}
            autoplay
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next-unique",
              prevEl: ".swiper-button-prev-unique",
            }}
            // navigation
            modules={[Pagination, Navigation]}
            className="text-white select-none"
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              // when window width is >= 480px
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {[1, 2, 3].map((i) => (
              <SwiperSlide key={i} className="">
                <img src={`/images/home-${i}-mobile.png`} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev-unique">
            <ChevronRightIcon className="w-8 h-8 text-gray-400" />
          </div>
        </div>

        {/* review */}
        <div className="bg-dark pt-20 pb-10">
          <div
            className="text-white text-xl lg:text-4xl text-center bg-dark pb-10"
            id="review"
          >
            <div>
              안심하고 사용하세요.
              <br />
              최근 도입한 고객사의 이용후기를 확인해보세요
            </div>
          </div>

          <div className="bg-dark py-10 max-width w-full px-4 flex items-center">
            <div className="hidden md:block swiper-button-prev-unique ">
              <ChevronLeftIcon className="w-14 h-14 text-white" />
            </div>
            <Swiper
              spaceBetween={50}
              slidesPerView={3}
              autoplay
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next-unique",
                prevEl: ".swiper-button-prev-unique",
              }}
              // navigation
              modules={[Pagination, Navigation]}
              className="text-white select-none"
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                // when window width is >= 480px
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            >
              {AppUserReviews.map((review, index) => (
                <SwiperSlide
                  key={index}
                  className="border p-10 border-[#444] rounded-2xl bg-[#252525]"
                >
                  <div className="flex items-center space-x-4">
                    <img
                      src={review.image}
                      className="w-20 h-20 rounded-full object-cover"
                      alt=""
                    />
                    <div className="space-y-1">
                      <div className="text-md">{review.hotelName}</div>
                      {/* <div className="text-base text-gray-400">
                          {review.hotelName}
                        </div> */}
                    </div>
                  </div>
                  <div className="mt-2">{review.review}</div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="hidden md:block swiper-button-next-unique">
              <ChevronRightIcon className="w-14 h-14 text-white" />
            </div>
          </div>
        </div>

        {/* function */}
        <div
          className="bg-[url('assets/images/bg-landing-2.png')] py-40 bg-cover"
          id="function"
        >
          <div className="text-white text-center px-10">
            <div className="text-lg lg:text-2xl xl:text-4xl">
              이미 국내 주요 숙박 업체들은 <br />
              이용후기를 전문적으로 관리하고 있습니다.
            </div>
            <div className="mt-2 lg:mt-10 text-base lg:text-lg xl:text-xl">
              이제 쉽고 편리하게 Zzamzari로 이용후기를 관리해보세요.
            </div>
          </div>
        </div>

        <div className="mx-auto flex flex-col items-center justify-center">
          {/* <span className="isolate inline-flex shadow-sm bg-gray-100 rounded-full ">
            <button
              type="button"
              onClick={() => setFunctionIndex(0)}
              className={classNames(
                "w-32 py-3 !rounded-full transition-colors text-lg",
                functionIndex === 0
                  ? "bg-orange-400 text-white "
                  : "bg-transparent text-gray-400"
              )}
            >
              후기수집
            </button>
            <button
              type="button"
              onClick={() => setFunctionIndex(1)}
              className={classNames(
                "w-32 py-3 !rounded-full transition-colors text-lg",
                functionIndex === 1
                  ? "bg-orange-400 text-white "
                  : "bg-transparent text-gray-400"
              )}
            >
              리뷰시스템
            </button>
            <button
              type="button"
              onClick={() => setFunctionIndex(2)}
              className={classNames(
                "w-32 py-3 !rounded-full transition-colors text-lg",
                functionIndex === 2
                  ? "bg-orange-400 text-white "
                  : "bg-transparent text-gray-400"
              )}
            >
              AI 리뷰
            </button>
          </span> */}

          <div className="text-center w-full bg-orange-50 py-20 px-10">
            <div className="max-width">
              <div>
                <div className="w-10 h-10 bg-primary text-white flex items-center justify-center rounded-full mx-auto">
                  01
                </div>
                <div className="text-xl lg:text-4xl font-bold mt-6">
                  이용후기 모아보기
                </div>
                <div className="text-sm lg:text-lg mt-6 pb-10 text-orange-400">
                  여러 사이트의 흩어져 있는 이용후기를 한번에 관리가 가능합니다.
                </div>
                <div className="hidden xl:block relative">
                  <div className="absolute top-0 left-0 w-fit px-8 py-3 !rounded-full text-lg bg-orange-400 text-white">
                    각 OTA에 흩어져 있는 고객의 리뷰를 <br /> 한 페이지에서
                    파악하고 관리합니다.
                  </div>
                  <div className="absolute top-1/2 left-1/3 w-fit px-8 py-3 !rounded-full text-lg bg-orange-400 text-white">
                    OTA엑스트라넷을 한페이지에서 관리 및 답변
                  </div>

                  <img
                    src="/images/function-1-phone.png"
                    alt=""
                    className="ml-auto mt-10"
                  />

                  <div className="absolute top-[0px] right-[530px]">
                    <img
                      src="/images/function-1-smile.png"
                      className="ml-auto"
                      alt=""
                    />
                    <img src="/images/function-1-review-1.png" alt="" />
                  </div>
                  <img
                    src="/images/function-1-review-2.png"
                    className="absolute top-[150px] right-[850px]"
                    alt=""
                  />
                  <img
                    src="/images/function-1-review-3.png"
                    className="absolute top-[330px] right-[1100px]"
                    alt=""
                  />
                  <img
                    src="/images/function-1-review-4.png"
                    className="absolute top-[430px] right-[900px]"
                    alt=""
                  />
                  <img
                    src="/images/function-1-review-5.png"
                    className="absolute top-[570px] right-[650px]"
                    alt=""
                  />
                  <img
                    src="/images/function-1-review-6.png"
                    className="absolute top-[470px] right-[500px]"
                    alt=""
                  />

                  <motion.img
                    initial={{ x: -850, y: 150, opacity: 0 }}
                    whileInView={{ x: 0, y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                    src="/images/function-1-review-2.png"
                    className="absolute top-[70px] right-10"
                    alt=""
                  />
                  <motion.img
                    initial={{ x: -900, y: 130, opacity: 0 }}
                    whileInView={{ x: 0, y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    viewport={{ once: true }}
                    src="/images/function-1-review-4.png"
                    className="absolute top-[300px] right-10"
                    alt=""
                  />

                  <motion.img
                    initial={{ x: -650, y: 40, opacity: 0 }}
                    whileInView={{ x: 0, y: 0, opacity: 1 }}
                    transition={{ delay: 1, duration: 0.5 }}
                    viewport={{ once: true }}
                    src="/images/function-1-review-5.png"
                    className="absolute top-[530px] right-10"
                    alt=""
                  />
                </div>
                <div className="xl:hidden flex justify-center">
                  <img src="/images/function-1-mobile.png" alt="" />
                </div>
              </div>
              <div className="mt-20">
                <div className="w-10 h-10 bg-primary text-white flex items-center justify-center rounded-full mx-auto">
                  02
                </div>
                <div className="text-xl lg:text-4xl font-bold mt-6">
                  인공지능(AI) 후기 관리
                </div>
                <div className="text-sm lg:text-lg mt-6 pb-10 text-orange-400">
                  고객이 남긴 이용후기를 인공지능(AI)가 분석하여 가장 최선의
                  응대가 가능합니다.
                </div>
                <div className="hidden md:block relative">
                  <img src="/images/function-2-off.png" alt="" />
                  <motion.img
                    initial={{ x: -0, y: 0, opacity: 0 }}
                    whileInView={{ x: 0, y: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    src="/images/function-2-on.png"
                    className="absolute top-14 left-0 right-0 bottom-0"
                    alt=""
                  />
                </div>
                <div className="md:hidden">
                  <img src="/images/function-2-mobile.png" alt="" />
                </div>
              </div>
              <>
                <div className="text-xl lg:text-4xl font-bold">
                  이용후기 데이터 분석 통계
                </div>
                <div className="text-sm lg:text-lg mt-10 pb-10 text-orange-400">
                  고객의 이용후기를 분석한 데이터를 통해 <br />
                  우리 업소에 장점과 단점을 한눈에 파악이 가능합니다.
                </div>
                <div className="hidden md:block relative">
                  <motion.img
                    initial={{ x: -0, y: 0, opacity: 0 }}
                    whileInView={{ x: 0, y: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    src="/images/function-3-bg.png"
                    className=""
                    alt=""
                  />
                </div>
                <div className="md:hidden">
                  <img src="/images/function-3-mobile.png" alt="" />
                </div>
              </>
            </div>
          </div>
        </div>

        {/* <div className="mt-20">
            <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
              <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                    className="relative"
                  >
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                      <span className="border-b-8 border-orange-200">
                        SPECIAL
                      </span>
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      이제부터는 <br />
                      숙소 업무에만 신경 쓰세요
                    </p>

                    <dl className="mt-10 space-y-10">
                      {transferFeatures.map((item) => (
                        <div key={item.id} className="relative">
                          <dt>
                            <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-orange-400 text-white">
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                              {item.name}
                            </p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">
                            {item.description}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </motion.div>

                  <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    viewport={{ once: true }}
                    className="relative -mx-4 mt-10 lg:mt-0"
                    aria-hidden="true"
                  >
                    <img
                      className="relative mx-auto"
                      width={490}
                      src={LandingSpecial}
                      alt=""
                    />
                  </motion.div>
                </div>

                <div className="relative mt-12 sm:mt-16 lg:mt-24">
                  <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
                    <motion.div
                      initial={{ x: 200, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.5, duration: 0.5 }}
                      viewport={{ once: true }}
                      className="lg:col-start-2"
                    >
                      <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        <span className="border-b-8 border-orange-200">
                          EXPERT
                        </span>
                      </h3>
                      <p className="mt-3 text-lg text-gray-500">
                        전문가가 고객님들과 <br />
                        함께합니다.
                      </p>

                      <dl className="mt-10 space-y-10">
                        {communicationFeatures.map((item) => (
                          <div key={item.id} className="relative">
                            <dt>
                              <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-orange-400 text-white">
                                <item.icon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                                {item.name}
                              </p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                              {item.description}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    </motion.div>

                    <motion.div
                      initial={{ x: -200, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0, duration: 0.5 }}
                      viewport={{ once: true }}
                      className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0"
                    >
                      <img
                        className="relative mx-auto"
                        width={490}
                        src={LandingExpert}
                        alt=""
                      />
                    </motion.div>
                  </div>
                </div>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0, duration: 0.5 }}
                    viewport={{ once: true }}
                    className="relative"
                  >
                    <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                      <span className="border-b-8 border-orange-200">
                        PRICE
                      </span>
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      가격은 최소한으로! <br />
                      모든 숙박업소 사장님을 응원합니다.
                    </p>

                    <dl className="mt-10 space-y-10">
                      {transferFeatures.map((item) => (
                        <div key={item.id} className="relative">
                          <dt>
                            <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-orange-400 text-white">
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                              {item.name}
                            </p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">
                            {item.description}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </motion.div>

                  <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    viewport={{ once: true }}
                    className="relative -mx-4 mt-10 lg:mt-0"
                    aria-hidden="true"
                  >
                    <img
                      className="relative mx-auto"
                      width={490}
                      src={LandingPrice}
                      alt=""
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div> */}

        {/* price */}
        {/* <div className="bg-white mt-0 " id="price">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="pb-16 xl:flex xl:items-center xl:justify-between">
                <div>
                  <h1 className="text-4xl lg:text-4xl font-bold tracking-tight">
                    <span className="text-gray-900">
                      모든 기능을 이 가격에{" "}
                    </span>
                    <span className="text-zinc-800 line-through">
                      ₩30,000 / 월
                    </span>
                    <div className="text-orange-400 mt-4">
                      ₩10,000 / 월 (프로모션 기간 한정)
                    </div>
                  </h1>
                  <p className="mt-5 text-xl text-gray-500">
                    유료 회원에게 모든 기능을 제공합니다
                  </p>
                </div>
                <Link to="/register" className="button !px-8 !py-3">
                  오늘 시작하세요
                </Link>
              </div>
              <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
                <div>
                  <h2 className="text-lg font-semibold text-orange-400">
                    Everything you need
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
                    All-in-one platform
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Explicabo doloremque sequi neque, harum aspernatur quisquam
                    perferendis modi cumque nam ducimus? Assumenda nisi amet
                    veritatis vitae illum dolor eum qui dolorem!
                  </p>
                </div>
                <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
                  <ul role="list" className="divide-y divide-gray-200">
                    {features.slice(0, 5).map((feature, featureIdx) =>
                      featureIdx === 0 ? (
                        <li key={feature} className="flex py-4 md:py-0 md:pb-4">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base text-gray-500">
                            {feature}
                          </span>
                        </li>
                      ) : (
                        <li key={feature} className="flex py-4">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base text-gray-500">
                            {feature}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                  <ul
                    role="list"
                    className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
                  >
                    {features.slice(5).map((feature, featureIdx) =>
                      featureIdx === 0 ? (
                        <li
                          key={feature}
                          className="flex py-4 md:border-t-0 md:py-0 md:pb-4"
                        >
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base text-gray-500">
                            {feature}
                          </span>
                        </li>
                      ) : (
                        <li key={feature} className="flex py-4">
                          <CheckIcon
                            className="h-6 w-6 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base text-gray-500">
                            {feature}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

        {/* application */}
        <div>
          {/* <div
              className="flex flex-col items-center pt-20 pb-20 bg-[url('assets/images/bg-landing-3.png')] bg-cover bg-no-repeat bg-center bg-dark"
              id="application"
            >
              <div className="text-orange-400 text-xl lg:text-4xl">
                zzamzari!
              </div>
              <div className="flex items-center space-x-4 mt-7">
                <div className="text-white text-xl lg:text-2xl ">
                  직접 확인해보시는 것이 가장 빠릅니다.
                </div>
                <button className="button !text-xl">무료로 시작하기</button>
              </div>
            </div> */}
          <div className="grid grid-cols-2 gap-10 max-width pt-20">
            <div className="flex flex-col justify-center items-center space-y-10">
              <div className="text-5xl font-bold">Zzamzari</div>
              <div className="text-lg text-gray-600">
                전용 어플을 통해 PC없이도 신규 리뷰, <br />
                미답변 리뷰를 편리하게 관리할 수 있습니다.
              </div>
              <div className="flex space-x-4">
                <div className="border rounded-lg px-10 py-4 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer">
                  <img
                    src="images/playstore.png"
                    className="w-6 h-6 object-contain"
                    alt=""
                  />
                  <span>Google Play</span>
                </div>
                <div className="flex space-x-4">
                  <div className="border rounded-lg px-10 py-4 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer">
                    <img
                      src="images/appstore.png"
                      className="w-6 h-6 object-contain"
                      alt=""
                    />
                    <span>App Store</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <img
                src="images/application-bg.png"
                alt=""
                className="max-h-[600px]"
              />
            </div>
          </div>
        </div>

        {/* contact */}
        <div className="bg-orange-400 bg-[url('assets/images/bg-contact.png')] bg-contain bg-no-repeat bg-left grid grid-cols-3">
          {/* <div className="relative bg-orange-400 "> */}
          {/* <h2 className="sr-only">Contact us</h2> */}
          <div></div>

          <div className="col-span-2 max-w-5xl">
            <div
              className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 bg-white"
              id="contact"
            >
              <h3 className="text-lg font-medium text-gray-900">문의 하기</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </LandingLayout>
    </Page>
  );
};

export default LandingPage;
