import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Logo } from "../components/Logo";
import LandingLayout from "../layouts/LandingLayout";
import { ErrorResponse } from "../types";
import { defaultAxios } from "../utils/axios";

export interface RegisterForm {
  email: string;
  password: string;
  name: string;
  phone: string;
  hotelType: string;
  hotelName: string;
  isVerifiedIdentification: boolean;
}

export enum PageState {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
}

export default function RegisterPage() {
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm<RegisterForm>({
    mode: "onChange",
  });

  // const onVerifyIdentity = async () => {
  //   const { IMP } = window;

  //   if (IMP) {
  //     /* 1. 가맹점 식별하기 */
  //     //TODO: 임시 가맹점 식별번호
  //     IMP.init("imp10391932");
  //     // IMP.certification(param, callback) 호출
  //     IMP.certification(
  //       {
  //         //   merchant_uid: "ORD20180131-0000011",
  //         //   //   m_redirect_url : "{리디렉션 될 URL}", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
  //         //   popup: false,
  //         //   //   company: "아임포트",
  //         //   //   carrier: "SKT",
  //         //   //   name: "",
  //         //   //   phone: "",
  //       },
  //       function (rsp: any) {
  //         console.log(rsp);

  //         // callback
  //         if (rsp.success) {
  //           setValue("isVerifiedIdentification", true);
  //           // 인증 성공 시 로직,
  //         } else {
  //           console.log(rsp);

  //           // setValue("isVerifiedIdentification", false);
  //           // toast.error("인증에 실패했습니다.");
  //           // 인증 실패 시 로직,
  //         }
  //       }
  //     );
  //   }
  // };

  const onRegister = async (data: RegisterForm) => {
    // if (!data.isVerifiedIdentification) {
    //   toast.error("본인 인증을 해주세요.");
    //   return;
    // }
    try {
      await defaultAxios.post("/auth/register", data);
      toast.success("회원가입에 성공 했습니다.");
      reset();
      navigate("/login");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert((error.response?.data as ErrorResponse).message);
        console.error(error);
      } else {
        alert(error);
        console.error(error);
      }
    }
  };

  return (
    <LandingLayout>
      <div className="flex min-h-full flex-col justify-center py-36 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onRegister)}>
              <Logo />
              <div className="text-xl font-bold">
                안녕하세요! <br />
                저녁의 자유로운 시간을 선물하는 <br />
                <span className="text-orange-400">잠자리</span>입니다.
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  이메일 주소
                </label>
                <div className="mt-1">
                  <input
                    {...register("email", { required: true })}
                    placeholder="이메일 주소를 입력해주세요."
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호
                </label>
                <div className="mt-1">
                  <input
                    {...register("password", { required: true })}
                    placeholder="비밀번호를 입력해주세요."
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  이름
                </label>
                <div className="mt-1">
                  <input
                    {...register("name", { required: true })}
                    placeholder="이름을 입력해주세요."
                    type="text"
                    autoComplete="name"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  핸드폰 번호
                </label>
                <div className="mt-1">
                  <input
                    {...register("phone", { required: true })}
                    placeholder="핸드폰 번호를 입력해주세요."
                    type="text"
                    autoComplete="phone"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  숙소 타입
                </label>
                <div className="mt-1">
                  <select
                    className="block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 focus:border-orange-300 focus:ring-orange-300 focus:outline-none"
                    {...register("hotelType", { required: true })}
                  >
                    <option value="호텔">호텔</option>
                    <option value="모텔">모텔</option>
                    <option value="펜션">펜션</option>
                    <option value="게스트하우스">게스트하우스</option>
                    <option value="캠핑/글램핑">캠핑/글램핑</option>
                    <option value="기타">기타</option>
                  </select>{" "}
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  숙소 이름
                </label>
                <div className="mt-1">
                  <input
                    {...register("hotelName", { required: true })}
                    placeholder="숙소이름을 입력해주세요."
                    type="text"
                    autoComplete="phone"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>
              {/* <div className="w-full ">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  본인 인증
                </label>
                <div className="flex items-center justify-between">
                  <div className="relative flex items-start bg-gray-100 px-4 py-2">
                    <div className="flex h-5 items-center">
                      <input
                        disabled
                        {...register("isVerifiedIdentification")}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="isVerifiedIdentification"
                        className="font-medium text-gray-700"
                      >
                        본인인증 확인 여부
                      </label>
                    </div>
                  </div>

                  <div
                    className="cursor-pointer button"
                    onClick={onVerifyIdentity}
                  >
                    본인인증 확인하기
                  </div>
                </div>
              </div> */}

              <div>
                <button
                  //   disabled={!isValid || !getValues("isVerifiedIdentification")}
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 disabled:bg-gray-300"
                >
                  회원가입
                </button>
              </div>

              <div className="divide-y"></div>

              <Link
                to="/login"
                className="text-center cursor-pointer group text-gray-800 hover:text-gray-700"
              >
                이미 가입한 회원이시라면?{" "}
                <span className="text-orange-400 group-hover:text-orange-500">
                  로그인하기
                </span>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
}
