import MainLayout from "../layouts/MainLayout";
import "react-quill/dist/quill.snow.css";
import CustomReactQuill from "../components/CustomReactQuill";
import { useForm } from "react-hook-form";
import { authAxios } from "../utils/axios";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Notice } from "../types/notice";

const NoticeCreatEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState<string>();
  const [contents, setContents] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (!id) return;
    authAxios.get(`/notice/${id}`).then((response) => {
      const notice = response.data.data as Notice;
      setTitle(notice.title);
      setContents(notice.contents);
    });
    // setNotice(data.notice)
  }, [id]);

  const onSubmit = async () => {
    if (!title) return alert("제목을 입력해주세요.");
    if (!contents) return alert("내용을 입력해주세요.");

    const payload = { title, contents };

    try {
      const response = id
        ? await authAxios.patch(`/notice/${id}`, payload)
        : await authAxios.post("/notice", payload);
      console.log(response.data);
      navigate(-1);
    } catch (e) {
      console.error(e);
      alert(`${id ? "수정" : "등록"} 실패`);
    }
  };
  return (
    <MainLayout>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                공지사항 등록
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                공지사항 등록 페이지입니다. 입력해주세요.
              </p>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  제목
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    name="title"
                    id="title"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-400 focus:ring-orange-400 sm:text-sm"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="title"
                    render={({ message }) => (
                      <span className="text-xs text-red-500">{message}</span>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="contents"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  내용
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <CustomReactQuill
                    placeholder={"공지사항 내용을 입력해주세요."}
                    setContents={setContents}
                    value={contents}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              onClick={() => navigate(-1)}
            >
              취소
            </button>
            <button type="submit" className="ml-3 button">
              입력
            </button>
          </div>
        </div>
      </form>
    </MainLayout>
  );
};

export default NoticeCreatEditPage;
