import React, { useEffect, useState } from "react";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { Hotel, HOTELS } from "../constants/hotels";
import { userState } from "../atoms/user.atom";
import { authAxios } from "../utils/axios";
import MainLayout from "../layouts/MainLayout";
import ModalBase from "../components/ModalBase";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "../utils/swr";
import { Account } from "../types/account";
import HotelIcon from "../components/HotelIcon";
import { getErrorMessage } from "../utils";
import { User, UserRole } from "../types";
import {
  ExclamationCircleIcon,
  LinkIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { authState } from "../atoms/auth.atom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";

export interface AddAccountRequest {
  username: string;
  password: string;
  provider: string;
  token?: string;
  hotelName?: string;
  hotelUrl?: string;
}

const MyPage = () => {
  const navigate = useNavigate();
  const [modalAddOpen, setAddModalOpen] = useState(false);
  const [modalRemoveOpen, setRemoveModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<Hotel>();
  const [accountApproved, setAccountApproved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const [auth, setAuth] = useRecoilState(authState);
  // const user = useRecoilValue(userState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddAccountRequest>();

  useEffect(() => {
    setAccountApproved(false);
  }, [modalAddOpen]);

  const { data, error } = useSWR("/accounts", fetcher);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (data?.data) {
      setAccounts(data.data.accounts);
    }
  }, [data?.data]);

  const [user, setUser] = useRecoilState(userState);

  const isUserPaid = () => {
    if (!user?.isPaid) {
      toast.error("유료 회원만 호텔을 등록할 수 있습니다.");
      navigate("/payment");
      return false;
    }
    return true;
  };

  const handleAddAccount = async (value: AddAccountRequest) => {
    if (loading || !selectedHotel) return;

    if (!isUserPaid()) {
      return;
    }

    try {
      // if (!selectedHotel.isReadOnly) {
      //   setLoading(true);
      //   await authAxios.post("/accounts/valid", {
      //     ...value,
      //     provider: selectedHotel.id
      //   });
      // }

      setLoading(true);
      toast.loading("호텔 확인 중입니다. 잠시만 기다려주세요.");
      await authAxios.post("/accounts", {
        ...value,
        provider: selectedHotel.id,
      });
      // await authAxios.get(`/crawler/${selectedHotel.id}`);

      await mutate("/accounts");
      toast.dismiss();
      toast.success("호텔 등록에 성공했습니다.");
      setAddModalOpen(false);
    } catch (error) {
      console.error(error);
      alert(getErrorMessage(error));
    } finally {
      reset();
      toast.dismiss();
      setLoading(false);
    }

    // try {
    //   setLoading(true);
    //   const {
    //     data: { success }
    //   } = await authAxios.get(`/crawler/${selectedHotel.id}`);

    //   setAddModalOpen(false);
    // } catch (error) {
    //   const { data } = await authAxios.delete(`/accounts/${selectedHotel.id}`);
    //   console.error(error);
    //   mutate("/accounts");
    // } finally {
    //   setLoading(false);
    // }
  };

  const onRemoveAccount = async () => {
    if (loading || !selectedHotel) return;
    try {
      await authAxios.delete(`/accounts?provider=${selectedHotel.id}`);
      await mutate("/accounts");
    } catch (error) {
      console.error(error);
      alert("계정 해제를 실패하였습니다.");
    } finally {
      setLoading(false);
      setRemoveModalOpen(false);
      reset();
    }
  };

  const onLogout = async () => {
    setAuth({ isLogin: false });
    setUser(null);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    navigate("/login", { replace: true });
  };
  const onUnregister = async () => {
    try {
      if (window.confirm("정말로 회원 탈퇴 하시겠습니까?")) {
        await authAxios.delete("/users/unregister");
        setAuth({ isLogin: false });
        setUser(null);
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        navigate("/login", { replace: true });
      }
    } catch (e) {
      console.error(e);
      toast.error("회원 탈퇴를 실패하였습니다.");
    }
  };

  return (
    <MainLayout title="마이페이지">
      <div className="flex space-x-10">
        {/* left */}
        <div className="w-[350px] shadow-lg flex flex-col items-center px-4 py-4 h-fit rounded-xl">
          {user?.role === "ADMIN" && (
            <div className="ml-auto px-3 py-1 bg-orange-50 w-fit text-primary rounded-full font-bold">
              관리자
            </div>
          )}
          <div className="mt-6">
            <UserIcon className="w-14 h-14 text-gray-400 bg-gray-50 rounded-lg p-2 mx-auto" />
          </div>

          <div className="mt-4 font-medium">{user?.email}</div>
          <div className="mt-1 text-light font-sm">
            {moment(user?.updatedAt).format("YYYY.MM.DD HH:mm:ss")}
          </div>

          <div className="mt-6 px-8">
            <div className="grid grid-cols-3 gap-12">
              {accounts.map((account) => (
                <div key={account.id} className="flex flex-col items-center">
                  <img
                    src={`/images/${account.provider}.png`}
                    alt=""
                    className="w-12 h-12 rounded-lg object-contain"
                  />
                  <div className="text-light mt-2">
                    {HOTELS[account.provider].name}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 p-4 w-full bg-orange-50 space-y-4">
            {/* <div className="flex">
              <span className="w-24">자동 댓글</span>
              <span>활성</span>
            </div> */}
            <div className="flex items-center">
              <span className="w-24">최근 로그인</span>
              <span className="text-[12px] font-light">
                {moment(user?.updatedAt).format("YYYY-MM-DD HH:mm")}
              </span>
            </div>
            <div className="flex">
              <span className="w-24">최근 결제일</span>
              <span className="text-[12px] font-light">
                {user?.payments && user.payments.length > 0 ? (
                  <span>
                    {moment(user?.payments[0].paid_at).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </span>
                ) : (
                  "결제 내역 없음"
                )}
              </span>
            </div>
            <div className="flex">
              <span className="w-24">결제 만료일</span>
              <span className="text-[12px] font-light">
                {user?.payments && user.payments.length > 0 ? (
                  <span>
                    {moment(user?.payments[0].endAt).format("YYYY-MM-DD HH:mm")}
                  </span>
                ) : (
                  "결제 내역 없음"
                )}
              </span>
            </div>
          </div>

          <div className="mt-4 ml-auto">
            <div
              className="text-light cursor-pointer hover:underline hover:text-gray-600"
              onClick={onLogout}
            >
              로그아웃
            </div>
          </div>

          <div className="mt-4 ml-auto">
            <div
              className="text-light cursor-pointer hover:underline hover:text-gray-600"
              onClick={onUnregister}
            >
              회원탈퇴
            </div>
          </div>
        </div>

        {/* right */}
        <div className="shadow-lg px-4 py-4 flex-grow">
          <div className="text-light text-sm border-b pb-4">My Information</div>
          <h3 className="title mt-8">호텔 연동</h3>
          <div>
            {selectedHotel && (
              <ModalBase open={modalAddOpen} setOpen={setAddModalOpen}>
                <form
                  className="space-y-8 divide-y divide-gray-200 rounded bg-white"
                  onSubmit={handleSubmit(handleAddAccount)}
                >
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6  sm:space-y-5 ">
                      <div className="flex space-x-4 items-start">
                        <HotelIcon siteName={selectedHotel.id} size={12} />
                        <div className="flex flex-col">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {selectedHotel.name} 리뷰 가져오기
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            {selectedHotel.name} 호텔 정보를 추가하시고 리뷰를
                            관리하세요.
                          </p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          사이트
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <input
                            disabled
                            value={selectedHotel.name}
                            type="text"
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-active focus:ring-active disabled:bg-gray-100 disabled:text-gray-400  sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="hotelName"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            호텔 이름
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              id="hotelName"
                              {...register("hotelName", {
                                required: "호텔 이름을 입력해주세요",
                              })}
                              placeholder="호텔 이름을 입력해주세요"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-active focus:ring-active sm:text-sm"
                            />
                            {errors.hotelName && (
                              <span className="error">
                                {errors.hotelName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-red-500 text-xs">
                          * 호텔 이름은 중복된 검색값이 나오지 않도록 최대한
                          자세하게 넣어주세요. (지역명 포함)
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="hotelUrl"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            호텔 사이트 주소
                          </label>

                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              id="hotelUrl"
                              {...register("hotelUrl", {
                                required: `${selectedHotel.name}에서 확인 가능한 호텔 주소를 입력해주세요`,
                              })}
                              placeholder="호텔 사이트 주소를 입력해주세요"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-active focus:ring-active  sm:text-sm"
                            />
                            {errors.hotelUrl && (
                              <span className="error">
                                {errors.hotelUrl.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-red-500 text-xs">
                          <div>
                            * 호텔 주소는 {selectedHotel.name} 사이트에서 확인
                            가능한 전체 주소를 넣어주세요.{" "}
                          </div>
                          <div>* {selectedHotel.exampleUrl} (예시주소)</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="text-md font-bold text-gray-500 mb-3">
                      {/* {!selectedHotel.isReadOnly
                        ? "* 리뷰 수집은 서버 상태에 따라 1시간 이내에 수집됩니다."
                        : "* 해당 사이트는 사이트 관리자 확인 후에 수집이 가능합니다. 영업일 기준 1~3일 소요될 수 있습니다."} */}
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="button-white"
                        disabled={loading}
                        onClick={() => {
                          if (loading) return;
                          setAddModalOpen(false);
                        }}
                      >
                        취소
                      </button>
                      <button type="submit" className="button ml-3">
                        {loading ? "확인 중..." : "리뷰 가져오기"}
                      </button>
                    </div>
                  </div>
                </form>
              </ModalBase>
            )}
            {selectedHotel && (
              <ModalBase open={modalRemoveOpen} setOpen={setRemoveModalOpen}>
                <>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-active focus:ring-offset-2"
                      onClick={() => setRemoveModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {selectedHotel.name} 호텔 연동 해제
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          정말로 {selectedHotel.name} 호텔 연동을 해제
                          하시겠습니까? 호텔 연동을 해제하면 등록된 리뷰
                          데이터가 전부 삭제 됩니다.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onRemoveAccount}
                    >
                      해제
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-active focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setRemoveModalOpen(false)}
                    >
                      취소
                    </button>
                  </div>
                </>
              </ModalBase>
            )}
          </div>

          {Object.keys(HOTELS).map((hotel) => {
            const account = accounts.filter(
              (account) => account.provider === hotel
            )[0];
            if (account) {
              return (
                <div
                  key={hotel}
                  className="bg-orange-50 px-4 py-4 clear-left flex items-center space-x-4 my-2 rounded-lg"
                >
                  <img
                    src={`/images/${hotel}.png`}
                    alt=""
                    className="w-10 h-10 rounded-lg object-contain"
                  />
                  <div className="flex flex-col space-y-0 flex-grow">
                    <div className="font-extrabold">{HOTELS[hotel].name}</div>
                    <div className="space-x-2">
                      <span className="text-primary">
                        등록{" "}
                        {moment(account.createdAt).format("YYYY.MM.DD HH:mm")}
                      </span>
                      <span>|</span>
                      {account.crawledAt ? (
                        <span className="text-light">
                          리뷰 마지막 수집일{" "}
                          {moment(account.crawledAt).format("YYYY.MM.DD HH:mm")}
                        </span>
                      ) : (
                        <span className="text-light">미수집</span>
                      )}
                    </div>
                    <div className="flex space-x-4">
                      <span>{account.hotelName}</span>
                      <div className="space-x-1 flex">
                        <a
                          href={account.hotelUrl}
                          className="flex text-gray-700 hover:text-primary hover:underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          접속
                        </a>
                        {/* <LinkIcon className="w-5 h-5 text-gray-700" /> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="button"
                      onClick={() => {
                        setRemoveModalOpen(true);
                        setSelectedHotel(HOTELS[hotel]);
                      }}
                    >
                      해제
                    </button>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={hotel}
                className="bg-gray-50 px-4 py-4 clear-left flex items-center space-x-4 my-2 rounded-lg"
              >
                <img
                  src={`/images/${hotel}.png`}
                  alt=""
                  className="w-10 h-10 rounded-lg object-contain"
                />
                <div className="flex flex-col space-y-0 flex-grow">
                  <div className="font-extrabold">{HOTELS[hotel].name}</div>
                  <div className="space-x-2">
                    <span>미등록</span>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="button-gray"
                    onClick={() => {
                      if (!isUserPaid()) {
                        return;
                      }
                      setAddModalOpen(true);
                      setSelectedHotel(HOTELS[hotel]);
                    }}
                  >
                    리뷰 가져오기
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
};

export default MyPage;
