export type Hotel = {
  id: string;
  name: string;
  adminPage: string;
  exampleUrl: string;
};

export const HOTELS: { [key: string]: Hotel } = {
  yanolza: {
    id: "yanolza",
    name: "야놀자",
    adminPage: "https://account.yanolja.biz",
    exampleUrl: "https://place-site.yanolja.com/places/10042441"
  },
  goodchoice: {
    id: "goodchoice",
    name: "여기어때",
    adminPage: "https://ad.goodchoice.kr",
    exampleUrl: "https://www.goodchoice.kr/product/detail?ano=55252"
  },
  naver: {
    id: "naver",
    name: "네이버",
    adminPage: "https://partner.booking.naver.com",
    exampleUrl:
      "https://map.naver.com/v5/search/%EB%AC%B4%EC%9D%98%EB%8F%84%20%ED%98%B8%ED%85%94/place/37402338"
  },
  booking: {
    id: "booking",
    name: "부킹닷컴",
    adminPage: "https://partner.booking.com/ko",
    exampleUrl:
      "https://www.booking.com/hotel/kr/heima-guesthouse-hongdae.ko.html?aid=304142"
  },
  expedia: {
    id: "expedia",
    name: "익스피디아",
    adminPage: "https://expediapartnercentral.com/",
    exampleUrl:
      "https://www.expedia.co.kr/Incheon-Hotels-Hotel-Hue-Loft.h6430150.Hotel-Information"
  },
  agoda: {
    id: "agoda",
    name: "아고다",
    adminPage: "https://ycs.agoda.com/ko-kr/kipp/public/login",
    exampleUrl:
      "https://www.agoda.com/ko-kr/incheon-illuwa/hotel/incheon-kr.html"
  },
  tripadvisor: {
    id: "tripadvisor",
    name: "트립어드바이저",
    adminPage: "https://www.tripadvisor.co.kr/Owners",
    exampleUrl:
      "https://www.tripadvisor.co.kr/Hotel_Review-g297884-d477913-Reviews-Ramada_Encore_by_Wyndham_Busan_Station-Busan.html"
  },
  trip: {
    id: "trip",
    name: "트립닷컴",
    adminPage: "https://ebooking.trip.com",
    exampleUrl: "https://kr.trip.com/hotels/detail/?cityId=410&hotelId=75055776"
  }
  //TODO: remove temporary
  // google: {
  //   id: "google",
  //   name: "구글",
  // adminPage: ""
  // },
};
