import { Payment } from "./payment";
import { Account } from "./account";
export interface User {
  id: number;
  email: string;
  createdAt: Date;
  isActive: boolean;
  isPaid: boolean;
  isAutoReplyEnable: boolean;
  provider: string;
  role: UserRole;
  updatedAt: Date;
  accounts: Account[];
  name: string;
  phone: string;
  hotelName: string;
  hotelType: string;
  payments: Payment[];
}

export interface ErrorResponse {
  success: boolean;
  error: string;
  timestamp: Date;
  path: string;
  statusCode: number;
  message: string;
}

export enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface AxiosError {
  error: string;
  message: string;
  path: string;
  statusCode: number;
  success: boolean;
  timestamp: string;
}

export enum ReviewUserCommentCategory {
  PRIMARY = "PRIMARY",
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}
export interface ReviewUserComment {
  id: number;
  contents: string;
  category: ReviewUserCommentCategory;
  createdAt: Date;
  updatedAt: Date;
}
