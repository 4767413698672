import { FC, useEffect, useState } from "react";
import { RequestPayParams, RequestPayResponse } from "../iamport-typings";
import { authAxios } from "../utils/axios";
import MainLayout from "../layouts/MainLayout";
import { userState } from "../atoms/user.atom";
import { useRecoilState, useRecoilValue } from "recoil";
import useSWR from "swr";
import { fetcher } from "../utils/swr";
import { Payment } from "../types/payment";
import moment from "moment";
import { IMP_UID } from "../constants";

const initialState: RequestPayParams = {
  pg: "html5_inicis.MOI8898444",
  pay_method: "card",
  name: "잠자리 일시 결제", // 주문명
  merchant_uid: `order_monthly_0001`, // 주문번호
  amount: 10000, // 결제금액
  buyer_tel: "",
  buyer_addr: "",
  buyer_postcode: "",
  // customer_uid: "" // 카드(빌링키)와 1:1로 대응하는 값
};

const PaymentPage: FC = () => {
  const [user, setUser] = useRecoilState(userState);
  const [month, setMonth] = useState<number>(1);
  const [payments, setPayments] = useState<Payment[]>([]);

  const { data, error } = useSWR(`payments`, fetcher);

  useEffect(() => {
    if (data?.data?.data) {
      setPayments(data.data.data);
    }
  }, [data?.data]);

  const onClickPayment = () => {
    // if (!user?.phone) {
    //   alert("전화번호를 입력해주세요.");
    //   return;
    // }
    const { IMP } = window;
    if (IMP) {
      IMP.init(IMP_UID);

      IMP.request_pay(
        {
          ...initialState,
          /**
           * 정기 결제시에 필요한 필드
           */
          // customer_uid: user.email,
          merchant_uid: `mid_${new Date().getTime()}`,
          buyer_email: user?.email,
          buyer_tel: user?.phone ?? "",
          buyer_name: user?.name,
          buyer_addr: user?.hotelName,
          amount: month * 10000,
          name: initialState.name + ` (${month}개월)`,
        },
        onPaymentAccepted
      );
    }
  };

  const onPaymentAccepted = async (response: RequestPayResponse) => {
    if (response.success) {
      try {
        await authAxios.post("/payments", response);
        alert("결제에 성공했습니다.");
        setUser((prevUser) => ({ ...prevUser!, isPaid: true }));
      } catch (e) {
        alert(e);
      }
    } else {
      alert(response.error_msg);
    }
  };

  return (
    <MainLayout>
      <>
        <h1 className="flex justify-center mx-auto text-2xl">결제 목록</h1>
        <table className="w-full bg-white border">
          <thead className="text-zinc-500 border">
            <tr>
              <th className="py-4 border">상품명</th>
              <th className="py-4 border">주문일자</th>
              <th className="py-4 border">종료일자</th>
              <th className="py-4 border">결제금액</th>
              <th className="py-4 border">주문번호</th>
              <th className="py-4 border">주문승인</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr className="border" key={payment.imp_uid}>
                <td className="border text-center py-8">{payment.name}</td>
                <td className="border text-center py-8">
                  {moment(payment.paid_at).format("YYYY.MM.DD HH:mm:ss")}
                </td>
                <td className="border text-center py-8">
                  {moment(payment.endAt).format("YYYY.MM.DD HH:mm:ss")}
                </td>
                <td className="border text-center py-8">
                  {payment.paid_amount.toLocaleString("ko-kr", {
                    currency: "KRW",
                    style: "currency",
                  })}
                </td>
                <td className="border text-center py-8">{payment.imp_uid}</td>
                <td className="border text-center py-8">
                  {payment.isVerified ? "승인" : "미승인"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {payments.length === 0 && (
          <div className="py-6 text-center text-md bg-white text-lg">
            결제 내역이 없습니다 😭
          </div>
        )}

        <hr className="my-10" />

        <div className="grid grid-cols-2 gap-10">
          <div className="card-container">
            <h1 className="text-2xl mb-8">주문정보</h1>
            <div className="space-y-4">
              <div className="">
                <label>선택상품</label>
                <textarea
                  disabled
                  rows={3}
                  className="input resize-none"
                  value={
                    "통합리뷰관리 이용권\n- 제공 OTA(야놀자, 여기어때, 네이버, 아고다, 부킹닷컴, 익스피디아, 트립닷컴, 구글)\n- 무제한(특정 OTA 이용 별도 문의)"
                  }
                />
              </div>

              <div>
                <label>결제 월</label>
                <select
                  name="month"
                  id="month"
                  onChange={(e) => setMonth(+e.target.value)}
                  className="input"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                    <option value={month} key={month}>
                      {month}달
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="mt-4">
                <label>연락처</label>

                <input className="input !bg-white" value={user?.phone} />
              </div> */}

              <div className="mt-4">
                <label>결제금액</label>
                <div className="space-x-2 input bg-gray-100 !text-gray-400">
                  <span className="line-through">
                    {(month * 30000).toLocaleString("ko-kr", {
                      style: "currency",
                      currency: "KRW",
                    })}
                  </span>
                  <span>promotion</span>
                </div>
                <input
                  disabled
                  className="input !bg-white"
                  value={(month * 10000).toLocaleString("ko-kr", {
                    style: "currency",
                    currency: "KRW",
                  })}
                />
              </div>

              <button
                className="button mt-4 !w-full"
                onClick={onClickPayment}
                disabled={
                  user?.isPaid || moment().isBefore(moment(payments[0]?.endAt))
                }
              >
                결제하기
              </button>
            </div>
          </div>

          <div className="flex flex-col justify-center">
            <div>
              <span className="font-bold"> 상품 안내 </span>
              <div>
                <ul>
                  <li className="list-disc">
                    본 이용권은 잠자리 사이트 내 통합 이용 후기 수집, 분석
                    기능을 사용할 수 있는 월 이용권입니다.
                  </li>
                  <li className="list-disc">
                    크롬 확장프로그램을 통해 제공하는 자동 답글 기능은 유료
                    회원에게 제공하는 무상 부가서비스로서 그 기능은 유료 회원의
                    동의 없이 축소 또는 확대될 수 있습니다.
                  </li>
                  <li className="list-disc">
                    회사는 별도의 고지 없이 월 요금제를 변경할 수 있으며, 기존
                    유료 회원은 이용중인 이용권의 만료일까지 변경된 월 요금제를
                    적용하지 않습니다.
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-5">
              <span className="font-bold">취소/환불 규정</span>
              <div>
                <ul>
                  <li className="list-disc">
                    유료 회원은 유료서비스 공급일 이후 3일 이내에 회사에 결제
                    취소를 요청할 수 있습니다.
                  </li>
                  <li className="list-disc">
                    유료서비스를 이용 중인 유료 회원이 탈퇴하거나 이용권을
                    환불하는 경우 정상금액 기준으로 일요금을 공제 후 환불됩니다.
                  </li>
                  <li className="list-disc">
                    구독중인 이용권이 만료되는 경우 자동으로 잠자리 웹사이트
                    계정이 정지되며, 만료일 이후 30일이 지나면 관련 정보는
                    폐기됩니다.
                  </li>
                  <li className="list-disc">
                    재가입 유료 회원의 경우 회사는 회원에게 별도의 등록비용,
                    복구비용 등을 청구할 수 있습니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  );
};

export default PaymentPage;
