import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useMe from "../hooks/useMe";
import Spinner from "../assets/images/loading.svg";
import { authAxios } from "../utils/axios";
import { useRecoilState } from "recoil";
import { authState } from "../atoms/auth.atom";

type Props = {};

export const PrivateRoute = (props: Props) => {
  // const { user, loading } = useMe();
  const [auth, setAuth] = useRecoilState(authState);

  if (auth?.isLogin) {
    return <Outlet />;
  }

  return <Navigate to={"/login"} replace={true} />;
};
