import React from "react";

type Props = {
  color?: string;
};

const RoundDot = ({ color = "bg-orange-400" }: Props) => {
  return <div className={`w-2 h-2 ${color} rounded-full`}></div>;
};

export default RoundDot;
