import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { Review } from "../types/review";
import { fetcher } from "../utils/swr";
import moment from "moment";
import { WordCloud } from "../types/wordCloud";
import WordCloudContainer from "../components/WordCloudContainer.jsx";
import { authAxios } from "../utils/axios";
import ReviewsReadOnly from "../components/ReviewsReadOnly";
import { classNames } from "../utils";

type Props = {};

export interface ReviewUserCommentInput {
  category: string;
  contents: string;
}

interface KeywordCount {
  [id: string]: number;
}

export const ReviewAnalysis = (props: Props) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [selectedReviews, setSelectedReviews] = useState<Review[]>([]);
  const [selectedKeyword, setSelectedKeyword] = useState<string>("");

  const [wordCloudTotal, setWordCloudTotal] = useState<WordCloud[]>([]);

  const [positveKeywords, setPositiveKeywords] = useState<KeywordCount>({});
  const [negativeKeywords, setNegativeKeywords] = useState<KeywordCount>({});

  const [stats, setStats] = useState({
    today: 0,
    complete: 0,
    average: 0.0,
  });

  // swr get reviews
  const { data, error } = useSWR("/reviews", fetcher, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data && data.data && !error) {
      setReviews(data.data.reviews);

      // console.log(data.data.reviews);

      let tempWordCloudTotal: { [id: string]: number } = {};
      let tempWordCloudPositive: KeywordCount = {};
      let tempWordCloudNegative: KeywordCount = {};

      data.data.reviews
        .filter((review: Review) => review.reviewAnalysis)
        .forEach((review: Review) => {
          review.reviewAnalysis.reviewKeywordCount.forEach(
            (keyword: WordCloud) => {
              tempWordCloudTotal[keyword.keyword] =
                (tempWordCloudTotal[keyword.keyword] ?? 0) + keyword.count;
            }
          );

          review.reviewAnalysis.reviewPositiveWord.forEach((keyword: any) => {
            tempWordCloudPositive.hasOwnProperty(keyword.word)
              ? (tempWordCloudPositive[keyword.word] += 1)
              : (tempWordCloudPositive[keyword.word] = 1);
          });
          setPositiveKeywords(tempWordCloudPositive);

          review.reviewAnalysis.reviewNegativeWord.forEach((keyword: any) => {
            tempWordCloudNegative.hasOwnProperty(keyword.word)
              ? (tempWordCloudNegative[keyword.word] += 1)
              : (tempWordCloudNegative[keyword.word] = 1);
          });
          setNegativeKeywords(tempWordCloudNegative);
        });

      // console.log(data.data.reviews);

      setWordCloudTotal(
        Object.keys(tempWordCloudTotal)
          .map((key) => {
            return { keyword: key, count: tempWordCloudTotal[key] };
          })
          .sort((a, b) => b.count - a.count)
          .slice(0, 50)
      );

      // setWordCloudPositive(
      //   Object.keys(tempWordCloudPositive)
      //     .map((key) => {
      //       return { keyword: key, count: tempWordCloudTotal[key] };
      //     })
      //     .sort((a, b) => b.count - a.count)
      //     .slice(0, 50)
      // );

      // setWordCloudNegative(
      //   Object.keys(tempWordCloudNegative)
      //     .map((key) => {
      //       return { keyword: key, count: tempWordCloudTotal[key] };
      //     })
      //     .sort((a, b) => b.count - a.count)
      //     .slice(0, 50)
      // );

      // console.log(
      //   data.data.reviews
      //     .filter((review: Review) => review.reviewAnalysis)
      //     .map((review: Review) =>
      //       review.reviewAnalysis.reviewKeywordCount
      //         .map((keyword: WordCloud) => ({
      //           text: keyword.keyword,
      //           value: keyword.count
      //         }))
      //         .flat()
      //     )
      //     .flat()
      // );
    }
  }, [data, error]);

  useEffect(() => {
    setStats({
      today: reviews.filter((review) => {
        const reviewDate = review.createdAt;
        const isSameDate =
          moment(new Date()).year() === moment(reviewDate).year() &&
          moment(new Date()).month() === moment(reviewDate).month() &&
          moment(new Date()).day() === moment(reviewDate).day();
        return isSameDate;
      }).length,
      complete: reviews.filter((review) => review.reviewComment).length,
      average:
        reviews.reduce((sum, review) => sum + review.stars, 0) / reviews.length,
    });
  }, [reviews]);

  const getReviewsByKeyword = async (
    isPositive: "positive" | "negative",
    keyword: string
  ) => {
    try {
      const { data } = await authAxios.get(
        `/reviews/keyword/${isPositive}?keyword=${keyword}`
      );
      setSelectedReviews(data.data);

      console.log(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <MainLayout title="리뷰 분석">
      <div className="space-y-8">
        <div>
          <div className="title">통합리뷰 - 리뷰 분석</div>
          <div className="text-lg text-gray-500">
            리뷰를 분석하여 대표 키워드, 긍정 키워드 부정 키워드 리스트를 확인할
            수 있습니다.
          </div>
        </div>
        {/* 워드클라우드 */}
        <div className="grid grid-cols-3 gap-8">
          <WordCloudContainer words={wordCloudTotal} title={"종합평가"} />
          <div className="card-container col-span-2">
            <div className="text-lg font-bold text-center pb-4 border-b">
              키워드 빈도 노출 리스트
            </div>
            <div className="grid grid-cols-2 pt-2">
              <div className="px-10">
                <div className="text-lg font-bold mb-4">긍정 키워드</div>
                <div className="flex flex-col justify-center">
                  {Object.entries(positveKeywords)
                    .sort((a, b) => b[1] - a[1])
                    .slice(0, 10)
                    .map(([key, value], index) => (
                      <div
                        key={index}
                        className={classNames(
                          "flex space-x-2 py-1 text-gray-600 cursor-pointer hover:underline",
                          selectedKeyword === key
                            ? "text-primary font-bold"
                            : ""
                        )}
                        onClick={() => {
                          setSelectedKeyword(key);
                          getReviewsByKeyword("positive", key);
                        }}
                      >
                        <span>{index + 1}.</span>
                        <span>{key}</span>
                        <span>({value})</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="px-10">
                <div className="text-lg font-bold mb-4">부정 키워드</div>
                <div className="flex flex-col justify-center ">
                  {Object.entries(negativeKeywords)
                    .sort((a, b) => b[1] - a[1])
                    .slice(0, 10)
                    .map(([key, value], index) => (
                      <div
                        key={index}
                        className={classNames(
                          "flex space-x-2 py-1 text-gray-600 cursor-pointer hover:underline",
                          selectedKeyword === key
                            ? "text-primary font-bold"
                            : ""
                        )}
                        onClick={() => {
                          setSelectedKeyword(key);
                          getReviewsByKeyword("negative", key);
                        }}
                      >
                        <span>{index + 1}.</span>
                        <span>{key}</span>
                        <span>({value})</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* <WordCloudContainer words={wordCloudPositive} title={"긍정평가"} />
          <WordCloudContainer words={wordCloudNegative} title={"부정평가"} /> */}
        </div>

        <div className="card-container">
          <h1 className="title mb-4">선택 키워드가 포함된 리뷰입니다.</h1>
          {!selectedKeyword && <div>키워드를 선택해주세요.</div>}
          <ReviewsReadOnly reviews={selectedReviews} />
          {/* {selectedReviews &&
            selectedReviews.map((review) => (
              <div key={review.id}>{review.contents}</div>
            ))} */}
        </div>
      </div>
    </MainLayout>
  );
};
