import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";
import { authAxios } from "./utils/axios";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/404";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ReviewPage from "./pages/ReviewsPage";
import NoticePage from "./pages/NoticePage";
import PaymentPage from "./pages/PaymentPage";
import "antd/dist/antd.css";
import { NoticeDetailPage } from "./pages/NoticeDetailPage";
import NoticeCreatEditPage from "./pages/NoticeCreatEditPage";
import AdminPage from "./pages/AdminPage";
import CustomerPage from "./pages/CustomerPage";
import MyPage from "./pages/MyPage";
import { PrivateRoute } from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReviewAnalysis } from "./pages/ReviewAnalysis";
import UserReply from "./pages/UserReplyPage";
import UserReplyPage from "./pages/UserReplyPage";
import UsagePage from "./pages/UsagePage";
import PrivacyPage from "./pages/PrivacyPage";
import RegisterPage from "./pages/RegisterPage";

const fetcher = (url: string) => {
  return authAxios.get(url).then((response) => response.data);
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <SWRConfig value={{ fetcher }}>
      <RecoilRoot>
        <React.StrictMode>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />}></Route>
              <Route path="/home" element={<PrivateRoute />}>
                <Route path="/home" element={<HomePage />}></Route>
              </Route>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route path="/register" element={<RegisterPage />}></Route>
              <Route path="/notice" element={<PrivateRoute />}>
                <Route path="/notice" element={<NoticePage />}></Route>
              </Route>
              <Route path="/notice/:id" element={<PrivateRoute />}>
                <Route
                  path="/notice/:id"
                  element={<NoticeDetailPage />}
                ></Route>
              </Route>
              <Route path="/notice/create-edit" element={<PrivateRoute />}>
                <Route
                  path="/notice/create-edit"
                  element={<NoticeCreatEditPage />}
                ></Route>
              </Route>
              <Route path="/notice/create-edit/:id" element={<PrivateRoute />}>
                <Route
                  path="/notice/create-edit/:id"
                  element={<NoticeCreatEditPage />}
                ></Route>
              </Route>
              <Route path="/review" element={<PrivateRoute />}>
                <Route path="/review" element={<ReviewPage />}></Route>
              </Route>

              <Route path="/review-analysis" element={<PrivateRoute />}>
                <Route
                  path="/review-analysis"
                  element={<ReviewAnalysis />}
                ></Route>
              </Route>

              <Route path="/user-reply" element={<PrivateRoute />}>
                <Route path="/user-reply" element={<UserReplyPage />}></Route>
              </Route>

              <Route path="/mypage" element={<PrivateRoute />}>
                <Route path="/mypage" element={<MyPage />}></Route>
              </Route>
              <Route path="/customer" element={<PrivateRoute />}>
                <Route path="/customer" element={<CustomerPage />}></Route>
              </Route>
              <Route path="/admin" element={<PrivateRoute />}>
                <Route path="/admin" element={<AdminPage />}></Route>
              </Route>
              <Route path="/payment" element={<PrivateRoute />}>
                <Route path="/payment" element={<PaymentPage />}></Route>
              </Route>

              <Route path="/usage" element={<UsagePage />}></Route>
              <Route path="/privacy" element={<PrivacyPage />}></Route>
              <Route path="*" element={<NotFoundPage />}></Route>
            </Routes>
          </BrowserRouter>
        </React.StrictMode>
      </RecoilRoot>
    </SWRConfig>
    <ToastContainer />
  </>
);

reportWebVitals();
