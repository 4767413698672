import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { classNames, getErrorMessage } from "../utils";
import axios from "axios";
import { Review } from "../types/review";
import { ThreeBody } from "@uiball/loaders";
import { authAxios } from "../utils/axios";
import { useSWRConfig } from "swr";
import { ReviewUserComment } from "../types";
import { toast } from "react-toastify";
import { CpuChipIcon, ListBulletIcon } from "@heroicons/react/24/outline";
import { AI_BACKEND_URL } from "../constants";
import { AIComment } from "../types/aiComment";
import { ReviewUserCommentIcon } from "./ReviewUserCommentIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import { HOTELS } from "../constants/hotels";

type Props = {
  review: Review;
  setReviews: React.Dispatch<React.SetStateAction<Review[]>>;
  reviewUserComments: ReviewUserComment[];
};

const ReviewCommentTextArea = ({
  review,
  setReviews,
  reviewUserComments
}: Props) => {
  const [loading, setLoading] = useState(false);

  const { mutate } = useSWRConfig();

  const { register, setValue, handleSubmit, getValues } = useForm();

  const onAiComment = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("AUTO_REPLY", review.contents);
      const response = await axios.post<AIComment>(
        `${AI_BACKEND_URL}/auto`,
        formData
      );
      // console.log(response.data);
      setValue("comment", response.data.value.comment);
    } catch (e) {
      alert("현재 이용이 불가능 합니다. 잠시 후에 이용해주세요.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onReply = async (data: any) => {
    try {
      toast.loading("댓글 등록 중...");
      await authAxios.post("/reviews/comment", {
        reviewId: review.id.split("-")[1],
        contents: data.comment,
        provider: review.id.split("-")[0]
      });

      setReviews((prev) => {
        return prev.map((r) => {
          if (r.id === review.id) {
            return {
              ...r,
              reviewComment: {
                reviewId: r.id,
                createdAt: new Date(),
                updatedAt: new Date(),
                id: r.id,
                contents: data.comment,
                isFromZzamzari: true
              }
            };
          }
          return r;
        });
      });

      await mutate("/reviews");
    } catch (error) {
      console.error(error);
      alert(getErrorMessage(error));
    } finally {
      toast.dismiss();
    }
  };

  return (
    <div className="min-w-max mt-2">
      <form onSubmit={handleSubmit(onReply)} className="relative">
        <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-orange-400 ">
          <label htmlFor="comment" className="sr-only">
            답글을 입력하세요.
          </label>
          <textarea
            rows={3}
            id="comment"
            className="block w-full resize-none border-0 py-3 px-3 focus:ring-0 sm:text-sm placeholder:text-gray-30 outline-none"
            placeholder="답글을 입력하세요."
            defaultValue={""}
            {...register("comment", {
              required: true
            })}
          />

          {/* Spacer element to match the height of the toolbar */}
          <div className="py-2" aria-hidden="true">
            {/* Matches height of button in toolbar (1px border + 36px content height) */}
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>

        <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
          <div className="flex items-center space-x-5">
            <div className="flex items-center">
              {loading ? (
                <ThreeBody size={20} speed={0.5} color="orange" />
              ) : (
                <button className="review-comment-button" onClick={onAiComment}>
                  <CpuChipIcon className="h-5 w-5" aria-hidden="true" />
                  {/* <img
                    src="/images/ai.png"
                    className="h-5 hover:text-white hover:bg-white"
                    alt=""
                  /> */}
                  <span className="">&nbsp;AI 답글</span>
                </button>
              )}
            </div>
            <div className="flex items-center">
              <Listbox
                value={false}
                onChange={(value) => {
                  setValue("comment", value);
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      기본 댓글 목록
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative -m-2.5 flex items-center justify-center rounded-full">
                        <div className="flex items-center">
                          <div className="review-comment-button">
                            <ListBulletIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            <span className="">&nbsp;사용자 정의 답글</span>
                          </div>
                        </div>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-3 -ml-6 py-2 -left-3 rounded-lg bg-white text-base border focus:outline-none sm:ml-auto w-60 sm:w-[300px] md:w-[400px] lg:w-[500px]">
                          {reviewUserComments.map((comment) => (
                            <Listbox.Option
                              key={comment.id}
                              className={({ active }) =>
                                classNames(
                                  active ? "bg-gray-100" : "bg-white",
                                  "relative cursor-pointer select-none py-1 px-3"
                                )
                              }
                              value={comment.contents}
                            >
                              <div className="flex items-center">
                                <span className="flex font-medium text-xs text-zinc-500 space-x-2">
                                  <ReviewUserCommentIcon
                                    category={comment.category}
                                  />
                                  <span>{comment.contents}</span>
                                </span>
                              </div>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className="flex-shrink-0 flex items-center space-x-2">
            <CopyToClipboard
              text={getValues("comment")}
              onCopy={() => {
                console.log(getValues("comment"));
                toast.success("복사되었습니다.");
              }}
            >
              <div className="w-10 h-10 bg-gray-100 rounded flex items-center justify-center text-gray-500 cursor-pointer">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  />
                </svg>
              </div>
            </CopyToClipboard>
            <a
              href={HOTELS[review.id.split("-")[0]].adminPage}
              target="_blank"
              rel="noreferrer"
              type="submit"
              className="button"
            >
              답글 등록 하러가기
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReviewCommentTextArea;
