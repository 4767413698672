import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { authState } from "../atoms/auth.atom";
import { userState } from "../atoms/user.atom";
import { Logo } from "../components/Logo";
import { ACCESS_TOKEN, IMP_UID, REFRESH_TOKEN } from "../constants";
import LandingLayout from "../layouts/LandingLayout";
import { ErrorResponse } from "../types";
import { defaultAxios } from "../utils/axios";

export interface LoginForm {
  email: string;
  password: string;
}

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<LoginForm>();

  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authState);
  const [user, setUser] = useRecoilState(userState);

  const onLogin = async (loginData: LoginForm) => {
    try {
      const {
        data: { accessToken, refreshToken, user: currentUser },
      } = await defaultAxios.post("/auth/login", {
        email: loginData.email,
        password: loginData.password,
      });
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      setAuth({ isLogin: true });
      setUser(currentUser);
      navigate("/home", { replace: true });
      // toast.success("로그인 성공");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert((error.response?.data as ErrorResponse).message);
        console.error(error);
      } else {
        alert(error);
        console.error(error);
      }
    }
  };

  return (
    <LandingLayout>
      <div className="flex min-h-full flex-col justify-center py-36 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onLogin)}>
              <Logo />
              <div className="text-xl font-bold">
                안녕하세요! <br />
                저녁의 자유로운 시간을 선물하는 <br />
                <span className="text-orange-400">잠자리</span>입니다.
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  이메일 주소
                </label>
                <div className="mt-1">
                  <input
                    {...register("email")}
                    placeholder="이메일 주소를 입력해주세요."
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호
                </label>
                <div className="mt-1">
                  <input
                    {...register("password")}
                    placeholder="비밀번호를 입력해주세요."
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2"
                >
                  로그인
                </button>
              </div>

              <div className="divide-y"></div>

              <Link
                to="/register"
                className="text-center cursor-pointer group text-gray-800 hover:text-gray-700"
              >
                아직 회원이 아니시라면?{" "}
                <span className="text-orange-400 group-hover:text-orange-500">
                  회원가입하기
                </span>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
}
