import ReactWordcloud from "react-wordcloud";
import RandomColor from 'randomcolor'
import { userState } from "../atoms/user.atom";
import { useRecoilValue } from "recoil";
import React from 'react'



const WordCloudContainer = ({ words, title }) => {
  const user = useRecoilValue(userState)
  words = words.map(word => {
    return { text: word.keyword, value: word.count };
  })
  const totalCount = words.map(word => word.value).reduce((sum, cur) => sum + cur, 0)

  return (
    <div className="w-full">
      <div className="card-container flex flex-col items-start">
        <div className="text-xl font-bold">워드 클라우드</div>
        <div className="text-lg mt-1 text-[#777] mb-4"> 우리{user.hotelName}의 대표 키워드는 무엇이 있을까요?</div>
        <ReactWordcloud
          callbacks={{
            getWordColor: (word) => RandomColor(),
            // onWordClick: console.log,
            // onWordMouseOver: console.log,
            getWordTooltip: (word) => `${(word.value / totalCount * 100).toFixed(2)}% (${(word.value)})`
          }}
          options={{
            fontSizes: [20, 80],
            rotations: 2,
            // rotationAngles: [0, 0, 90, -90],
            rotationAngles: [0],
            padding: 0,
          }}
          // size={[1024, 500]}
          words={words}
        // maxWords={50}
        />
      </div>
    </div>
  );
};

export default React.memo(WordCloudContainer);
