interface Props {
  size?: number | undefined;
  showLabel?: boolean;
}

export function Logo({ size, showLabel }: Props) {
  return (
    <div className="flex items-center space-x-4">
      <img
        src="/images/logo-with-horizontal-title.png"
        alt="logo"
        className="h-8 "
      />
      {showLabel && (
        <span className="text-xl font-bold text-gray-800">잠자리</span>
      )}
    </div>
  );
}
