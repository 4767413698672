import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  KeyIcon
} from "@heroicons/react/24/solid";
import { ReviewUserCommentCategory } from "../types";

interface ReviewUserCommentProps {
  category: ReviewUserCommentCategory;
}

//TODO: change icons
export const ReviewUserCommentIcon = ({ category }: ReviewUserCommentProps) => {
  switch (category) {
    case ReviewUserCommentCategory.PRIMARY:
      return (
        <img
          src={"/images/reply-primary.png"}
          className="w-6 h-6"
          alt="대표답글"
        />
      );
    case ReviewUserCommentCategory.POSITIVE:
      return (
        <img
          src={"/images/reply-positive.png"}
          className="w-6 h-6"
          alt="긍정답글"
        />
      );
    case ReviewUserCommentCategory.NEGATIVE:
      return (
        <img
          src={"/images/reply-negative.png"}
          className="w-6 h-6"
          alt="부정답글"
        />
      );
  }
};
