import React from "react";
import { USAGE_TEXT } from "../constants";
import LandingLayout from "../layouts/LandingLayout";

type Props = {};

const UsagePage = (props: Props) => {
  return (
    <LandingLayout>
      <div className="whitespace-pre-wrap max-width py-20">{USAGE_TEXT}</div>
    </LandingLayout>
  );
};

export default UsagePage;
