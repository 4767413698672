import { JSXElementConstructor, ReactElement, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../atoms/user.atom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import { authState } from "../atoms/auth.atom";
import Header from "../components/Header";
import useSWR from "swr";
import { fetcher } from "../utils/swr";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import Banner from "../components/Banner";
interface Props {
  children?:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactElement[];
  title?: string;
}

export type AuthState = {
  isLogin: boolean;
};

export default function MainLayout({ children, title }: Props) {
  const [auth, setAuth] = useRecoilState<AuthState>(authState);
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();

  const { data: userData } = useSWR("/users/me", fetcher);
  useEffect(() => {
    if (userData && userData?.data) {
      setUser(userData.data.user);
    }
  }, [setUser, userData]);

  useEffect(() => {
    if (
      !localStorage.getItem(ACCESS_TOKEN) ||
      !localStorage.getItem(REFRESH_TOKEN)
    ) {
      navigate("/login", { replace: true });
      setAuth({ isLogin: false });
      setUser(null);
    }
  }, [navigate, setAuth, setUser]);

  return (
    <Page title={title}>
      <div className="min-h-screen">
        <div className="">
          <main className="min-h-screen shadow">
            <Header />
            {!user?.isPaid && <Banner />}
            <div className="p-5 font-nanum text-[#222] pt-8 pb-20 bg-gray-50">
              <div className="max-w-screen-xl mx-auto mt-8">{children}</div>
            </div>
            <Footer isMain />
          </main>
        </div>
      </div>
    </Page>
  );
}
