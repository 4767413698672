import moment from "moment";
import React from "react";
import { ModalAutoReplyModal } from "../components/ModalAlert";
import RoundDot from "../components/RoundDot";
import MainLayout from "../layouts/MainLayout";
import { useCallback, useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import { Switch } from "@headlessui/react";
import { fetcher } from "../utils/swr";
import { classNames, getErrorMessage } from "../utils";
import { authAxios } from "../utils/axios";
import { ReviewUserComment } from "../types";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms/user.atom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

export interface ReviewUserCommentInput {
  category: string;
  contents: string;
}

type Props = {};

const UserReplyPage = (props: Props) => {
  const [openAutoReplyModal, setAutoReplyModal] = useState(false);
  const [reviewUserComments, setReviewUserComments] = useState<
    ReviewUserComment[]
  >([]);

  const { register, handleSubmit, reset } = useForm<ReviewUserCommentInput>();

  const user = useRecoilValue(userState);

  const { mutate } = useSWRConfig();

  const onAutoReplyStatusChange = useCallback(
    async (status: boolean) => {
      if (!status) {
        if (window.confirm("자동 답글을 비활성화 하시겠습니까?")) {
          await authAxios.post("users/autoreply", {
            status,
          });
          await mutate("/users/me");
        }
      } else {
        if (
          window.confirm(
            "자동 답글 활성화를 하시면 활성화 이후에 수집된 리뷰에 자동으로 대표 답글이 달립니다. 정말로 활성화 하시겠습니까?"
          )
        ) {
          await authAxios.post("users/autoreply", {
            status,
          });
          await mutate("/users/me");
        }
        // setAutoReplyModal(status);
      }
    },
    [mutate]
  );

  const onAddReviewUserComment = async ({
    category,
    contents,
  }: ReviewUserCommentInput) => {
    try {
      console.log(category);
      if (
        reviewUserComments.filter((c) => c.category === category).length >= 3
      ) {
        alert("카테고리는 3개까지만 등록 가능합니다.");
        return;
      }
      toast.loading("사용자 답글을 저장중입니다.");
      await authAxios.post("/users/comment", {
        category,
        contents,
      });
      reset();
      await mutate("/users/comment");
    } catch (error) {
      alert(getErrorMessage(error));
    } finally {
      toast.dismiss();
    }
  };

  const onDeleteReviewUserComment = async (id: number) => {
    try {
      if (window.confirm("정말로 사용자 답글을 삭제하시겠습니까?")) {
        toast.loading("사용자 답글을 삭제중입니다.");
        await authAxios.delete(`/users/comment/${id}`);
      }
      await mutate("/users/comment");
    } catch (e) {
      console.error(e);
      alert(getErrorMessage(e));
    } finally {
      toast.dismiss();
    }
  };

  // swr get review user comments
  const { data: userCommentData, error: userCommentError } = useSWR(
    "/users/comment",
    fetcher
  );

  useEffect(() => {
    if (userCommentData && userCommentData.data && !userCommentError) {
      setReviewUserComments(userCommentData.data.comments);
    }
  }, [userCommentData, userCommentError]);

  return (
    <MainLayout title="답글관리">
      <div className=" border-[#eee] bg-white">
        <h3 className="title">AI 답글</h3>
        <div className="max-w-2xl text-sm text-gray-500">
          <span>
            고도화된 AI가 리뷰를 분석하여 문맥에 적합한 답글을 제안해줍니다.
            AI가 제안하는 답글을 활용 / 수정하여 리뷰를 더욱 효율적으로
            관리하세요
          </span>
        </div>
        <div className="bg-white rounded border boder-gray-100 flex flex-col items-center justify-center py-10 mt-4">
          <ExclamationCircleIcon className="w-16 h-16 mb-4 text-orange-400" />
          <div>
            AI 답글 기능은 전용 크롬 확장 프로그램에서 사용할 수 있습니다.
          </div>
          <a
            href="https://chrome.google.com/webstore/detail/%EC%9E%A0%EC%9E%90%EB%A6%AC-ai-%EB%8B%B5%EA%B8%80-%EC%83%9D%EC%84%B1-%ED%94%8C%EB%9F%AC%EA%B7%B8%EC%9D%B8/akmnmbnjmmeehfpofadimpepmkblmooe?hl=ko&authuser=3"
            target="_blank"
            className="underline text-gray-600 mt-4 hover:text-gray-500"
            rel="noreferrer"
          >
            다운로드 바로가기
          </a>
        </div>
      </div>

      {/* ai switch */}
      {/* <div className="flex space-x-14 relative bg-white card-container border rounded">
        <Switch.Group as="div" className="py-5">
          <Switch.Label as="h3" className="title" passive>
            자동 답글 활성화
          </Switch.Label>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <Switch.Description>
                <span>
                  자동 답글을 활성화하시면 새로 등록된 리뷰에 답글이 자동으로
                  달립니다.
                  <br />
                  자동 답글은 설정하신 대표 답글 중에서 랜덤으로 선택됩니다.
                </span>
              </Switch.Description>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-10 sm:flex sm:flex-shrink-0 sm:items-center">
              <Switch
                checked={user?.isAutoReplyEnable ?? false}
                onChange={onAutoReplyStatusChange}
                className={classNames(
                  user?.isAutoReplyEnable ? "bg-orange-400" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    user?.isAutoReplyEnable ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </div>
        </Switch.Group>
      </div> */}

      {/* 사용자 답글 */}
      <div className=" border-[#eee] py-10">
        <h3 className="title">사용자 답글</h3>
        <div className="max-w-2xl text-sm text-gray-500">
          <span>
            사용자 답글은 리뷰에 대한 답글을 미리 설정해두고, 새로 등록된 리뷰에
            자동으로 답글을 달 수 있습니다. <br /> 대표 / 긍정 / 부정 답글은
            3개까지 저장할 수 있습니다.
          </span>
        </div>
        <div className="mt-4">
          <form
            action=""
            className="flex space-x-1"
            onSubmit={handleSubmit(onAddReviewUserComment)}
          >
            <select
              id="category"
              {...register("category")}
              className="border border-[#e5e5e5] rounded-l-lg focus:ring-primary focus:border-primary"
            >
              <option value="PRIMARY">대표</option>
              <option value="POSITIVE">긍정</option>
              <option value="NEGATIVE">사과</option>
            </select>
            <input
              required
              {...register("contents", { required: true })}
              className="flex-grow border !border-[#e5e5e5]  focus:ring-primary focus:border-primary"
              type="text"
              placeholder="사용자 답글 내용을 입력해주세요."
            />
            <button className="button !rounded-none !px-8">등록</button>
          </form>
          <div className="mt-6">
            {["PRIMARY", "POSITIVE", "NEGATIVE"].map((c) =>
              reviewUserComments
                .filter((comment) => comment.category === c)
                .map((comment, index) => (
                  <div
                    className="flex space-x-10 items-center border px-4 py-2 bg-white my-1"
                    key={comment.id}
                  >
                    {/* icon */}
                    <div className="flex-shrink-0 w-10 space-x-2 flex items-center">
                      {c === "PRIMARY" && (
                        <>
                          <img
                            src={"/images/reply-primary.png"}
                            className="w-8 h-8"
                            alt="대표답글"
                          />
                          {/* <div className={classNames("text-primary font-bold")}>
                            {index + 1}
                          </div> */}
                        </>
                      )}
                      {c === "POSITIVE" && (
                        <>
                          <img
                            src={"/images/reply-positive.png"}
                            className="w-8 h-8"
                            alt="긍정답글"
                          />
                        </>
                      )}
                      {c === "NEGATIVE" && (
                        <>
                          <img
                            src={"/images/reply-negative.png"}
                            className="w-8 h-8"
                            alt="부정답글"
                          />
                        </>
                      )}
                    </div>

                    <div className="flex justify-between space-x-4 w-full items-center">
                      <div>{comment.contents}</div>
                      <div className="flex-shrink-0 items-center hover:underline">
                        <button
                          className="text-red-400"
                          onClick={() => onDeleteReviewUserComment(comment.id)}
                        >
                          삭제
                        </button>
                      </div>
                      {/* <div className="flex space-x-4 mt-0 text-sm text-light"> */}
                      {/* <div>
                          {moment(comment.createdAt).format("YYYY-MM-DD")}
                        </div> */}
                      {/* <div>|</div> */}
                      {/* <div className="flex space-x-2 items-center hover:underline">
                          <button
                            className=""
                            onClick={() =>
                              onDeleteReviewUserComment(comment.id)
                            }
                          >
                            삭제
                          </button>
                        </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      <ModalAutoReplyModal open={openAutoReplyModal} />
    </MainLayout>
  );
};

export default UserReplyPage;
