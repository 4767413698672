import { Link } from "react-router-dom";
import { classNames } from "../utils";

type Props = {
  isMain?: boolean;
};

const Footer = ({ isMain }: Props) => {
  return (
    <footer className={classNames(isMain ? "bg-white" : "bg-dark")}>
      <div className="max-width overflow-hidden py-12 px-4 sm:px-28 lg:px-32 flex flex-col md:flex-row space-y-4 md:space-y-0">
        <div className="md:w-[20%] md:text-right mr-0 md:mr-20 font-bold text-gray-400">
          (주) 남디스테이션
        </div>
        <div className="text-gray-400 space-y-4">
          <div className="flex space-x-10">
            <Link to={"/usage"}>
              <span className="hover:text-gray-500 cursor-pointer text-gray-400">
                이용약관
              </span>
            </Link>
            <Link to={"/privacy"}>
              <span className="hover:text-gray-500 cursor-pointer text-gray-400">
                개인정보처리방침
              </span>
            </Link>
          </div>
          <div>
            <div>
              상호 : 남디스테이션 / 대표 : 이번영 / 연락처 : 070-7954-7097 /
              e-mail : ben.lee@zzamzari.com
            </div>
            <div>
              주소 : 서울시 중구 청계천로 40, 707호(한국관광공사 서울센터)
            </div>
            <div>사업자등록번호 : 542-13-02138</div>
            <div>통신판매번호: 제 2022-서울중구-2267호</div>
            <div>
              남디스테이션에서 운영하는 사이트에서 판매되는 모든 상품은
              남디스테이션에서 책임지고 있습니다.
            </div>
            <div>민원 담당자 이 번영/ 연락처 070-7954-7097</div>
            <div>&copy; zzamzari, All right reserved.</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
