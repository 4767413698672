import emialjs from "emailjs-com";
import {
  EMAIL_JS_SERVICE_ID,
  EMAIL_JS_TEMPLATE_ID,
  EMAIL_JS_USER_ID
} from "../constants";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms/user.atom";
import { useEffect } from "react";
type Props = {};

interface ContactInputForm {
  type: string;
  region: string;
  body: string;
  name: string;
  email: string;
  phone: string;
}

const ContactForm = (props: Props) => {
  const { register, getValues, setValue, handleSubmit, reset } =
    useForm<ContactInputForm>();

  const user = useRecoilValue(userState);

  const onSendEmail = ({
    body,
    name,
    email,
    phone,
    type,
    region
  }: ContactInputForm) => {
    emialjs
      .send(
        EMAIL_JS_SERVICE_ID,
        EMAIL_JS_TEMPLATE_ID,
        {
          body: body,
          name: name,
          fromEmail: email,
          phone: phone,
          type,
          region
        },
        EMAIL_JS_USER_ID
      )
      .then((result) => {
        toast.success(
          "문의사항이 접수 되었습니다. 이메일이나 핸드폰으로 답변 드리겠습니다."
        );
        reset();
        // console.log(result.text);
      })
      .catch((error) => {
        toast.error(
          "문의사항 접수가 실패했습니다. zzamzariapp@gmail.com 으로 문의사항 연락주시면 감사하겠습니다."
        );
        // console.log(error.text);
      });
  };

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
      setValue("name", user.name);
      setValue("phone", user.phone);
    }
  }, [register, setValue, user]);

  return (
    <form
      onSubmit={handleSubmit(onSendEmail)}
      className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div>
        <label
          htmlFor="type"
          className="block text-sm font-medium text-gray-900"
        >
          구분
        </label>
        <div className="mt-1">
          <select className="input" {...register("type")}>
            <option value="호텔">호텔</option>
            <option value="모텔">모텔</option>
            <option value="펜션">펜션</option>
            <option value="게스트하우스">게스트하우스</option>
            <option value="캠핑/글램핑">캠핑/글램핑</option>
            <option value="기타">기타</option>
          </select>
        </div>
      </div>

      <div>
        <label
          htmlFor="type"
          className="block text-sm font-medium text-gray-900"
        >
          지역
        </label>
        <div className="mt-1">
          <select className="input" {...register("region")}>
            <option value="서울특별시">서울특별시</option>
            <option value="부산광역시">부산광역시</option>
            <option value="인천광역시">인천광역시</option>
            <option value="대구광역시">대구광역시</option>
            <option value="광주광역시">광주광역시</option>
            <option value="울산광역시">울산광역시</option>
            <option value="세종특별자치시">세종특별자치시</option>
            <option value="경기도">경기도</option>
            <option value="강원도">강원도</option>
            <option value="충청북도">충청북도</option>
            <option value="충청남도">충청남도</option>
            <option value="전라북도">전라북도</option>
            <option value="전라남도">전라남도</option>
            <option value="경상북도">경상북도</option>
            <option value="경상남도">경상남도</option>
            <option value="제주특별자치도">제주특별자치도</option>
          </select>
        </div>
      </div>

      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-900"
        >
          담당자
        </label>
        <div className="mt-1">
          <input
            {...register("name")}
            disabled={user?.name ? true : false}
            required
            type="text"
            autoComplete="name"
            className="input"
            placeholder="담당자 이름을 입력해주세요."
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-900"
        >
          이메일
        </label>
        <div className="mt-1">
          <input
            disabled={user?.email ? true : false}
            {...register("email")}
            required
            type="email"
            autoComplete="email"
            className="input"
            placeholder="이메일을 입력해주세요."
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-900"
        >
          핸드폰
        </label>
        <div className="mt-1">
          <input
            {...register("phone")}
            disabled={user?.phone ? true : false}
            type="text"
            autoComplete="phone"
            className="input"
            placeholder="휴대폰 번호를 입력해주세요."
          />
        </div>
      </div>
      {/* <div className="sm:col-span-2">
        <label
          htmlFor="subject"
          className="block text-sm font-medium text-gray-900"
        >
          제목
        </label>
        <div className="mt-1">
          <input
            {...register("subject")}
            type="text"
            name="subject"
            id="subject"
            className="input"
          />
        </div>
      </div> */}
      <div className="sm:col-span-2">
        <div className="flex justify-between">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-900"
          >
            내용
          </label>
          <span id="message-max" className="text-sm text-gray-500">
            최대 500자
          </span>
        </div>
        <div className="mt-1">
          <textarea
            {...register("body")}
            rows={5}
            className="input"
            aria-describedby="message-max"
            placeholder="문의하실 내용을 입력해주세요."
          />
        </div>
      </div>
      <div className="sm:col-span-2 sm:flex sm:justify-end">
        <button className="button">문의하기</button>
      </div>
    </form>
  );
};

export default ContactForm;
