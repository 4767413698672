import { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "./Logo";
import {
  ArchiveBoxIcon,
  ChartBarSquareIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { userState } from "../atoms/user.atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthState } from "../layouts/MainLayout";
import { authState } from "../atoms/auth.atom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import { UserRole } from "../types";

const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];
const resources = [
  {
    name: "Help Center",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "#",
    icon: LifebuoyIcon,
  },
  {
    name: "Guides",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "#",
    icon: BookmarkSquareIcon,
  },
  {
    name: "Events",
    description:
      "See what meet-ups and other events we might be planning near you.",
    href: "#",
    icon: CalendarIcon,
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously.",
    href: "#",
    icon: ShieldCheckIcon,
  },
];
const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [user, setUser] = useRecoilState(userState);
  const [auth, setAuth] = useRecoilState<AuthState>(authState);

  const location = useLocation();
  const navigate = useNavigate();

  const onLogout = async () => {
    setAuth({ isLogin: false });
    setUser(null);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    navigate("/login", { replace: true });
  };

  return (
    <Popover className="relative bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={"/home"}>
              <Logo />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      "nav-menu flex items-center",
                      location.pathname === ("/review")
                        ? "!text-orange-400 font-bold"
                        : ""
                    )}
                  >
                    <span>통합리뷰</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500",
                        location.pathname === ("/review")
                          ? "!text-orange-400 font-bold"
                          : ""
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          <Link
                            key={"review"}
                            to={"/review"}
                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                          >
                            <ArchiveBoxIcon
                              className="h-6 w-6 flex-shrink-0 text-orange-600"
                              aria-hidden="true"
                            />
                            <div className="ml-4">
                              <p className="text-base font-medium text-gray-900">
                                리뷰 관리
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                리뷰 관리를 통해 리뷰를 관리해보세요.
                              </p>
                            </div>
                          </Link>

                          <Link
                            key={"review-analysis"}
                            to={"/review-analysis"}
                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                          >
                            <ChartBarSquareIcon
                              className="h-6 w-6 flex-shrink-0 text-orange-600"
                              aria-hidden="true"
                            />
                            <div className="ml-4">
                              <p className="text-base font-medium text-gray-900">
                                리뷰 분석
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                리뷰 분석을 통해 리뷰를 분석해보세요.
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}

            <Link
              to={"/review"}
              className={`nav-menu ${
                location.pathname === "/review"
                  ? "!text-orange-400 font-bold"
                  : ""
              }`}
            >
              리뷰 관리
            </Link>

            <Link
              to={"/review-analysis"}
              className={`nav-menu ${
                location.pathname === "/review-analysis"
                  ? "!text-orange-400 font-bold"
                  : ""
              }`}
            >
              리뷰 분석
            </Link>

            <Link
              to={"/user-reply"}
              className={`nav-menu ${
                location.pathname === "/user-reply"
                  ? "!text-orange-400 font-bold"
                  : ""
              }`}
            >
              답글관리
            </Link>

            {/* <Link
              to={"/payment"}
              className={`nav-menu ${
                location.pathname === ("/payment")
                  ? "!text-orange-400 font-bold"
                  : ""
              }`}
            >
              결제
            </Link>

            <Link
              to={"/mypage"}
              className={`nav-menu ${
                location.pathname === ("/mypage")
                  ? "!text-orange-400 font-bold"
                  : ""
              }`}
            >
              마이페이지
            </Link> */}

            {/* <Link
              to={"/mobile"}
              className={`nav-menu ${
                location.pathname === ("/mobile")
                  ? "!text-orange-400 font-bold"
                  : ""
              }`}
            >
              모바일앱
            </Link> */}

            {user && user.role === UserRole.ADMIN && (
              <Link
                to={"/admin"}
                className={`nav-menu !text-orange-400 underline ${
                  location.pathname === "/admin"
                    ? "!text-orange-400 font-bold"
                    : ""
                }`}
              >
                관리자
              </Link>
            )}
          </Popover.Group>

          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0 space-x-2">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center bg-white  text-sm font-medium">
                  {user && (
                    <img
                      src="/images/user.png"
                      alt=""
                      className="w-[24px] h-[24px]"
                    />
                  )}
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={"/mypage"}
                          className={classNames(
                            active ? "bg-gray-100" : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm hover:text-gray-700"
                          )}
                        >
                          마이페이지
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={"/payment"}
                          className={classNames(
                            active ? "bg-gray-100" : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm hover:text-gray-700"
                          )}
                        >
                          결제
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://play.google.com/store/apps/details?id=com.zzamzari.mobile"
                          target={"_blank"}
                          rel="noreferrer"
                          className={classNames(
                            active ? "bg-gray-100" : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm hover:text-gray-700"
                          )}
                        >
                          전용 어플 연동
                          <br />
                          (안드로이드)
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://apps.apple.com/kr/app/%EC%9E%A0%EC%9E%90%EB%A6%AC-%EC%88%99%EB%B0%95-%EC%97%85%EC%86%8C-%EB%A6%AC%EB%B7%B0-%ED%86%B5%ED%95%A9-%EA%B4%80%EB%A6%AC/id1662234978"
                          target={"_blank"}
                          rel="noreferrer"
                          className={classNames(
                            active ? "bg-gray-100" : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm hover:text-gray-700"
                          )}
                        >
                          전용 어플 연동
                          <br />
                          (아이폰)
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={onLogout}
                        >
                          로그아웃
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=orange&shade=600"
                    alt="Your Company"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {/* {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <item.icon
                        className="h-6 w-6 flex-shrink-0 text-orange-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))} */}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Pricing
                </a>

                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Docs
                </a>
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{" "}
                  <a href="#" className="text-orange-600 hover:text-orange-500">
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
function userecoilValue(userState: any) {
  throw new Error("Function not implemented.");
}
