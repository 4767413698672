import { useEffect, useState } from "react";
import { Review } from "../types/review";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import { authAxios } from "../utils/axios";
import moment from "moment";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "../utils/swr";
import { Notice } from "../types/notice";
import { UserRole } from "../types/";
import { ColumnsType } from "antd/lib/table";
import { Progress, Space } from "antd";
import HotelIcon from "../components/HotelIcon";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { HOTELS } from "../constants/hotels";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import Chart from "react-google-charts";
import StarRatings from "react-star-ratings";
import { HashLink } from "react-router-hash-link";
import { useRecoilState } from "recoil";
import { userState } from "../atoms/user.atom";
import ProgressBar from "@ramonak/react-progress-bar";
import { Payment } from "../types/payment";
import ReviewsSummary from "../components/ReviewsSummary";
import ReviewsReadOnly from "../components/ReviewsReadOnly";

SwiperCore.use([Navigation, Pagination]);

const HomePage = () => {
  const navigate = useNavigate();
  // const [user, setUser] = useRecoilState(userState);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [notices, setNotices] = useState<Notice[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [randomImageReview, setRandomImageReview] = useState<Review>();

  const [user, setUser] = useRecoilState(userState);

  // const { user, loading } = useMe();

  const { mutate } = useSWRConfig();

  const { data: noticeResponse, error: noticeError } = useSWR(
    `/notice`,
    fetcher
  );

  useEffect(() => {
    if (noticeResponse?.data) {
      setNotices(noticeResponse.data.notices);
    }
  }, [noticeResponse]);

  const { data: reviewResponse, error: reviewError } = useSWR(
    "/reviews",
    fetcher
  );

  useEffect(() => {
    if (reviewResponse?.data) {
      setReviews(reviewResponse.data.reviews);
    }
  }, [reviewResponse]);

  const { data: payResponse } = useSWR("/payments", fetcher);

  useEffect(() => {
    if (payResponse?.data) {
      setPayments(payResponse.data.data);
    }
  }, [payResponse]);

  const onDeleteNotice = async (id: number) => {
    if (window.confirm(`정말로 공지를 삭제하시겠습니까?`)) {
      try {
        const { data } = await authAxios.delete(`/notice/${id}`);
        await mutate("/notice");
      } catch (error) {
        console.error(error);
        alert("삭제 실패");
      }
    }
  };

  const noticeColumns: ColumnsType<Notice> = [
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      render: (text, notice) => (
        <Link to={`/notice/${notice.id}`}>
          <span className="text-gray-800 hover:text-orange-400">{text}</span>
        </Link>
      )
    },
    {
      title: "등록일",
      key: "createdAt",
      width: "150px",
      render: (createdAt) => (
        <span className="text-xs text-gray-400">
          {moment(createdAt).format("YYYY-MM-DD")}
        </span>
      )
    }
  ];

  // useEffect(() => {
  //   const photoReviews = reviews.filter((review) => review.photos);
  //   if (photoReviews.length > 0) {
  //     const randomIndex = Math.floor(Math.random() * photoReviews.length);
  //     setRandomImageReview(photoReviews[randomIndex]);
  //   }
  // }, [reviews]);

  if (user?.role === UserRole.ADMIN) {
    noticeColumns.push({
      title: "관리자 기능",
      key: "action",
      width: "100px",
      render: (_, notice) => (
        <Space size="middle">
          <div
            className="cursor-pointer hover:text-orange-400"
            onClick={() => navigate(`/notice/create-edit/${notice.id}`)}
          >
            수정
          </div>
          <div
            className="cursor-pointer hover:text-orange-400"
            onClick={() => onDeleteNotice(notice.id)}
          >
            삭제
          </div>
        </Space>
      )
    });
  }

  const reviewColumns: ColumnsType<Review> = [
    {
      title: "리뷰",
      dataIndex: "contents",
      key: "contents",
      render: (contents, notice) => (
        <div className="flex space-x-2 items-center">
          <HotelIcon siteName={notice.id.split("-")[0]} size={6} />
          <span className="text-gray-600">{contents}</span>
        </div>
      )
    },
    {
      title: "등록일",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
      render: (createdAt, record) => {
        return (
          <div className="text-gray-500 text-xs text-center">
            {moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        );
      }
    }
  ];

  return (
    <MainLayout>
      {/* Page title & actions */}
      <div className="space-y-8">
        {notices && notices.length > 0 && (
          <div className="w-full bg-white shadow-sm rounded py-4 px-4 flex space-x-2">
            <BellAlertIcon className="w-6 h-6 text-primary " />

            <Link to={`/notice/${notices[0].id}`}>
              <div className="text-base text-gray-600 hover:text-gray-500">
                {notices[0].title}
              </div>
            </Link>
          </div>
        )}
        {/* 공지사항 */}
        {/* <div className="">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-3xl font-bold">공지사항</h1>
            <div className="flex space-x-4">
              <Link to={"/notice"} className="button !text-[18px] !px-8 !py-4">
                공지사항 전체보기
              </Link>
              {user?.role === UserRole.ADMIN && (
                <Link
                  to={"/notice/create-edit"}
                  className="button-outline !text-[18px] !px-8 !py-4"
                >
                  공지사항 추가
                </Link>
              )}
            </div>
          </div>
          <div className="flex-grow space-y-4">
            {notices &&
              notices.slice(0, 5).map((notice) => (
                <div
                  key={notice.id}
                  className="cursor-pointer hover:bg-gray-50 rounded-lg shadow border border-gray-200 bg-white px-6 py-4 flex flex-col justify-center"
                >
                  <Link to={`/notice/${notice.id}`}>
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="text-[20px] text-[#222]">
                          {notice.title}
                        </div>
                        <div className="text-[14px] text-[#777]">
                          {moment(notice.createdAt).format("YYYY.MM.DD")}
                        </div>
                      </div>
                      <ArrowRightCircleIcon className="text-orange-400 w-[35px] h-[35px] cursor-pointer" />
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div> */}
        <div className="grid grid-cols-3 gap-6">
          <div className="card-container text-xl space-y-6 flex flex-col justify-center">
            <div>
              안녕하세요
              <br />
              <span className="font-bold">{user?.hotelName}</span> 님
            </div>
            <hr />
            <div>
              <h1 className="font-bold">등록 호텔</h1>
              <div className="flex space-x-2">
                {user?.accounts.map((account) => (
                  <img
                    src={`/images/${account.provider}.png`}
                    alt=""
                    className="w-8 h-8 object-contain rounded-full"
                  />
                ))}
              </div>
            </div>
            <hr />
            <div>
              <div className="mb-4 font-bold">결제(구독) 정보</div>
              {payments && payments.length >= 1 && (
                <div className="text-base space-y-2">
                  <div className="flex space-x-2">
                    <div>서비스 시작일: </div>
                    <div>
                      {moment(payments[0].paid_at).format(
                        "YYYY.MM.DD HH:mm:ss"
                      )}
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <div>서비스 종료일: </div>
                    <div>
                      {moment(payments[0].endAt).format("YYYY.MM.DD HH:mm:ss")}{" "}
                      (
                      <span className="font-bold">
                        D-
                        {moment(payments[0].endAt).diff(
                          moment(new Date()),
                          "days"
                        )}
                      </span>
                      )
                    </div>
                  </div>
                  <div></div>
                  <div className="flex space-x-2">
                    <div>결제카드: </div>
                    <div>
                      {payments[0].card_name} {payments[0].card_number}
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <div>결제금액: </div>
                    <div>
                      {payments[0].paid_amount.toLocaleString("ko-kr", {
                        currency: "KRW",
                        style: "currency"
                      })}
                      원
                    </div>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      href={payments[0].receipt_url}
                      target="_blank"
                      className="hover:underline underline-offset-4 text-primary hover:text-primary/80"
                    >
                      영수증 확인하기
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ReviewsSummary reviews={reviews} />
        </div>

        <div className="grid grid-cols-3 gap-6">
          {/* OTA별 리뷰 수 */}
          <div className="card-container !px-0">
            <div className="title mb-4 w-full px-6">OTA별 리뷰 수</div>
            {reviews && reviews.length ? (
              <div className="flex items-center h-full">
                <Chart
                  chartType="PieChart"
                  options={{
                    // title: "OTA별 리뷰 개수",
                    is3D: true
                    // legend: "none"
                  }}
                  // height={"600px"}
                  width={"100%"}
                  data={[
                    ["OTA", "개수"],
                    ...Object.values(HOTELS).map((hotel) => [
                      hotel.name,
                      reviews.filter(
                        (review) => review.id.split("-")[0] === hotel.id
                      ).length
                    ])
                  ]}
                />
              </div>
            ) : (
              <div className="text-xl mt-10 flex space-x-2 items-center">
                <div>😭 등록된 리뷰가 없습니다. 호텔을 등록해주세요.</div>
                <Link to={"/mypage"} className="button">
                  이동
                </Link>
              </div>
            )}
          </div>
          {/* 월별 리뷰 수 변동 추이 */}
          <div className="col-span-2 shadow-lg p-6">
            <h1 className="title mb-4">월별 리뷰 수 변동 추이</h1>
            <Chart
              className=""
              chartType="Line"
              options={{
                title: "월별 리뷰수 변동 추이",
                curveType: "function",
                legend: { position: "bottom" }
              }}
              height={"400px"}
              width={"100%"}
              data={[
                ["년/월", ...Object.values(HOTELS).map((hotel) => hotel.name)],
                ...[5, 4, 3, 2, 1, 0].map((month) => [
                  moment(new Date())
                    .subtract({ month: month })
                    .format("YYYY/MM"),
                  ...Object.keys(HOTELS).map(
                    (hotel) =>
                      reviews.filter(
                        (review) =>
                          review.id.split("-")[0] === hotel &&
                          moment(review.createdAt).isSame(
                            moment(new Date()).subtract({ month: month }),
                            "month"
                          )
                      ).length
                  )
                ])
              ]}
            />
          </div>
        </div>

        {/* Picture Review */}
        {/* {randomImageReview && (
          <div className="flex">
            <div className="flex-1 relative">
              <img
                src={randomImageReview.photos.split(",")[0]}
                alt=""
                className="object-cover py-24 max-h-[550px] w-full"
              />
              <div className="-z-10 absolute top-0 bottom-0 left-[60%] right-0 bg-[#f7f7f7] rounded-l-[200px]"></div>
            </div>
            <div className="flex-1 bg-[#f7f7f7] px-10 ">
              <div className="flex flex-col h-full justify-center">
                <div className="flex items-center space-x-2">
                  <RoundDot />
                  <div className="text-orange-400 text-[16px]">
                    PICTURE REVIEW
                  </div>
                </div>
                <div className="text-[30px] font-bold mt-4">
                  고객님이 올려주신 사진리뷰 입니다.
                </div>
                <div className="text-[18px] text-[#444] mt-10">
                  {randomImageReview.contents}
                </div>
                <div className="mt-4 text-[14px] text-[#777]">2020.11.12</div>
              </div>
            </div>
          </div>
        )} */}

        <div className="card-container">
          <div className="flex space-x-6 items-end mb-4">
            <div className="title">
              최근 리뷰 <span className="font-bold">5</span> 건
            </div>
            <HashLink
              to={"/review#reviews"}
              className="hover:underline cursor-pointer text-zinc-700 hover: hover:text-zinc-600"
            >
              더보기
            </HashLink>
          </div>
          <hr />
          <ReviewsReadOnly reviews={reviews.slice(0, 5)} />
        </div>
      </div>
    </MainLayout>
  );
};

export default HomePage;
